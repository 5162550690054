import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import Feature from '../components/Feature';
import exercisesFeatureImage from '../assets/listening_thumbnail.png';
import essayFeatureImage from '../assets/writing_thumbnail.png';
import speakingFeatureImage from '../assets/speaking_thumbnail.png';
import languagesFeatureImage from '../assets/flags_thumbnail.png';
import examPreparationImage from '../assets/table_completion_thumbnail.png';

const Features = ({
  personalisedExercisesRef,
  speakingPracticeRef,
  writingPracticeRef,
  languagesRef,
  examPreparationRef,
}) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center mb-5">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('features_title')}</h2>
        <p className="fs-5 text-body-secondary">{t('features_subtitle')}</p>
      </div>
      <div ref={personalisedExercisesRef}>
        <Feature
          heading={t('feature_personalised_exercises_heading')}
          description={t('feature_personalised_exercises_description')}
          image={exercisesFeatureImage}
          buttonText={t('feature_personalised_exercises_button')}
          textOnLeft={true}
        />
      </div>
      <div ref={examPreparationRef}>
        <Feature
          heading={t('feature_exam_preparation_heading')}
          description={t('feature_exam_preparation_description')}
          image={examPreparationImage}
          buttonText={t('feature_exam_preparation_button')}
          textOnLeft={false}
        />
      </div>
      <div ref={speakingPracticeRef}>
        <Feature
          heading={t('feature_speaking_practice_heading')}
          description={t('feature_speaking_practice_description')}
          image={speakingFeatureImage}
          buttonText={t('feature_speaking_practice_button')}
          textOnLeft={true}
        />
      </div>
      <div ref={writingPracticeRef}>
        <Feature
          heading={t('feature_writing_practice_heading')}
          description={t('feature_writing_practice_description')}
          image={essayFeatureImage}
          buttonText={t('feature_writing_practice_button')}
          textOnLeft={false}
        />
      </div>
      <div ref={languagesRef}>
        <Feature
          heading={t('feature_languages_heading')}
          description={t('feature_languages_description')}
          image={languagesFeatureImage}
          buttonText={t('feature_languages_button')}
          textOnLeft={true}
          isLast={true}
        />
      </div>
    </div>
  );
};

export default Features;
