import React from 'react';

const Image = ({ url }) => {
  return (
    url ? (
      <div className="alert alert-light d-flex justify-content-center">
        <img src={url} alt="Img" className="img-fluid" width="300px" />
      </div>
    ) : null
  );
};

export default Image;
