import highlightCorrections from "../utils/highlightCorrections";

const CorrectedResponse = ({text}) => {
  if (!text) return null;
  
  return (
    <div className="alert alert-light mt-3" role="alert">
      <div>{highlightCorrections(text)}</div>
    </div>
  );
};

export default CorrectedResponse;
