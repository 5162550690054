import React, { useState, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import ErrorMessage from '../components/ErrorMessage';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatCost = (cost) => {
  return `$${cost.toFixed(5)}`;
};

const formatNumber = (num) => num.toLocaleString();

const ExerciseLimitsCard = ({ metrics }) => {
  const sections = ['reading', 'listening', 'writing', 'speaking', 'total'];
  
  // Prepare data for the chart
  const data = {
    labels: sections,
    datasets: [
      // Basic Users
      {
        label: 'Basic - Limit',
        data: sections.map(section => metrics.exerciseLimits.basic[section]),
        backgroundColor: 'rgba(54, 162, 235, 0.3)',  // Light
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      {
        label: 'Basic - Maximum',
        data: sections.map(section => 
          (metrics.metricsBySection[section] || {}).basicMaxUsage || 0
        ),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',  // Medium
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      {
        label: 'Basic - Average',
        data: sections.map(section => {
          const sectionData = metrics.metricsBySection[section] || {};
          return sectionData.basicUserCount 
            ? sectionData.basicUserCount / (metrics.userMetrics.total - metrics.userMetrics.premium - metrics.userMetrics.admin)
            : 0;
        }),
        backgroundColor: 'rgba(54, 162, 235, 0.9)',  // Dark
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      // Premium Users
      {
        label: 'Premium - Limit',
        data: sections.map(section => metrics.exerciseLimits.premium[section]),
        backgroundColor: 'rgba(255, 99, 132, 0.3)',  // Light
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      {
        label: 'Premium - Maximum',
        data: sections.map(section => 
          (metrics.metricsBySection[section] || {}).premiumMaxUsage || 0
        ),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',  // Medium
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      {
        label: 'Premium - Average',
        data: sections.map(section => {
          const sectionData = metrics.metricsBySection[section] || {};
          return sectionData.premiumUserCount 
            ? sectionData.premiumUserCount / metrics.userMetrics.premium
            : 0;
        }),
        backgroundColor: 'rgba(255, 99, 132, 0.9)',  // Dark
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      // Admin Users
      {
        label: 'Admin - Limit',
        data: sections.map(section => metrics.exerciseLimits.admin[section]),
        backgroundColor: 'rgba(75, 192, 192, 0.3)',  // Light
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      },
      {
        label: 'Admin - Maximum',
        data: sections.map(section => 
          (metrics.metricsBySection[section] || {}).adminMaxUsage || 0
        ),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',  // Medium
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      },
      {
        label: 'Admin - Average',
        data: sections.map(section => {
          const sectionData = metrics.metricsBySection[section] || {};
          return sectionData.adminUserCount 
            ? sectionData.adminUserCount / metrics.userMetrics.admin
            : 0;
        }),
        backgroundColor: 'rgba(75, 192, 192, 0.9)',  // Dark
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      }
    ]
  };

  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Exercise Usage by User Type</h2>
        
        {/* Chart */}
        <Bar
          data={data}
          options={{
            responsive: true,
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Exercise Types'
                }
              },
              y: {
                stacked: false,
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Number of Exercises'
                }
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Exercise Usage and Limits by User Type'
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return `${context.dataset.label}: ${context.raw.toFixed(1)}`;
                  }
                }
              }
            }
          }}
        />

        {/* Table (existing code) */}
        <div className="table-responsive mt-4">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Section</th>
                <th colSpan="3" className="text-center">Basic</th>
                <th colSpan="3" className="text-center">Premium</th>
                <th colSpan="3" className="text-center">Admin</th>
              </tr>
              <tr>
                <th></th>
                <th>Avg</th>
                <th>Max</th>
                <th>Limit</th>
                <th>Avg</th>
                <th>Max</th>
                <th>Limit</th>
                <th>Avg</th>
                <th>Max</th>
                <th>Limit</th>
              </tr>
            </thead>
            <tbody>
              {sections.map(section => {
                const sectionData = metrics.metricsBySection[section] || {};
                
                const basicAvg = sectionData.basicUserCount 
                  ? sectionData.basicUserCount / (metrics.userMetrics.total - metrics.userMetrics.premium - metrics.userMetrics.admin)
                  : 0;
                const premiumAvg = sectionData.premiumUserCount
                  ? sectionData.premiumUserCount / metrics.userMetrics.premium
                  : 0;
                const adminAvg = sectionData.adminUserCount
                  ? sectionData.adminUserCount / metrics.userMetrics.admin
                  : 0;

                return (
                  <tr key={section}>
                    <td className="text-capitalize">{section}</td>
                    <td>{basicAvg.toFixed(1)}</td>
                    <td>{sectionData.basicMaxUsage || 0}</td>
                    <td>{metrics.exerciseLimits.basic[section]}</td>
                    <td>{premiumAvg.toFixed(1)}</td>
                    <td>{sectionData.premiumMaxUsage || 0}</td>
                    <td>{metrics.exerciseLimits.premium[section]}</td>
                    <td>{adminAvg.toFixed(1)}</td>
                    <td>{sectionData.adminMaxUsage || 0}</td>
                    <td>{metrics.exerciseLimits.admin[section]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const UserCostsCard = ({ metrics }) => {
  const sections = ['reading', 'listening', 'writing', 'speaking', 'total'];
  
  // Helper functions
  const getMaxCostPerExercise = (section) => {
    const sectionData = metrics.metricsBySection[section] || {};
    return sectionData.maxCost || 0;
  };

  const getAvgCostPerExercise = (section) => {
    const sectionData = metrics.metricsBySection[section] || {};
    return sectionData.averageCost || 0;
  };

  // Prepare data for the chart
  const data = {
    labels: sections,
    datasets: [
      // Basic Users
      {
        label: 'Basic - Max Limit',
        data: sections.map(section => metrics.exerciseLimits.basic[section] * getMaxCostPerExercise(section)),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',  // Lightest
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      {
        label: 'Basic - Avg Limit',
        data: sections.map(section => metrics.exerciseLimits.basic[section] * getAvgCostPerExercise(section)),
        backgroundColor: 'rgba(54, 162, 235, 0.4)',  // Light
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      {
        label: 'Basic - Maximum',
        data: sections.map(section => 
          (metrics.metricsBySection[section] || {}).basicMaxUsage * getAvgCostPerExercise(section) || 0
        ),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',  // Medium
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      {
        label: 'Basic - Average',
        data: sections.map(section => {
          const sectionData = metrics.metricsBySection[section] || {};
          return (sectionData.basicUserCount 
            ? (sectionData.basicUserCount / (metrics.userMetrics.total - metrics.userMetrics.premium - metrics.userMetrics.admin))
            : 0) * getAvgCostPerExercise(section);
        }),
        backgroundColor: 'rgba(54, 162, 235, 0.8)',  // Dark
        borderColor: 'rgba(54, 162, 235, 1)',
        stack: 'basic'
      },
      // Premium Users
      {
        label: 'Premium - Max Limit',
        data: sections.map(section => metrics.exerciseLimits.premium[section] * getMaxCostPerExercise(section)),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',  // Lightest
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      {
        label: 'Premium - Avg Limit',
        data: sections.map(section => metrics.exerciseLimits.premium[section] * getAvgCostPerExercise(section)),
        backgroundColor: 'rgba(255, 99, 132, 0.4)',  // Light
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      {
        label: 'Premium - Maximum',
        data: sections.map(section => 
          (metrics.metricsBySection[section] || {}).premiumMaxUsage * getAvgCostPerExercise(section) || 0
        ),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',  // Medium
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      {
        label: 'Premium - Average',
        data: sections.map(section => {
          const sectionData = metrics.metricsBySection[section] || {};
          return (sectionData.premiumUserCount 
            ? sectionData.premiumUserCount / metrics.userMetrics.premium
            : 0) * getAvgCostPerExercise(section);
        }),
        backgroundColor: 'rgba(255, 99, 132, 0.9)',  // Dark
        borderColor: 'rgba(255, 99, 132, 1)',
        stack: 'premium'
      },
      // Admin Users
      {
        label: 'Admin - Max Limit',
        data: sections.map(section => metrics.exerciseLimits.admin[section] * getMaxCostPerExercise(section)),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',  // Lightest
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      },
      {
        label: 'Admin - Avg Limit',
        data: sections.map(section => metrics.exerciseLimits.admin[section] * getAvgCostPerExercise(section)),
        backgroundColor: 'rgba(75, 192, 192, 0.4)',  // Light
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      },
      {
        label: 'Admin - Maximum',
        data: sections.map(section => 
          (metrics.metricsBySection[section] || {}).adminMaxUsage * getAvgCostPerExercise(section) || 0
        ),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',  // Medium
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      },
      {
        label: 'Admin - Average',
        data: sections.map(section => {
          const sectionData = metrics.metricsBySection[section] || {};
          return (sectionData.adminUserCount 
            ? sectionData.adminUserCount / metrics.userMetrics.admin
            : 0) * getAvgCostPerExercise(section);
        }),
        backgroundColor: 'rgba(75, 192, 192, 0.9)',  // Dark
        borderColor: 'rgba(75, 192, 192, 1)',
        stack: 'admin'
      }
    ]
  };

  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Cost Usage by User Type</h2>
        
        {/* Chart */}
        <Bar data={data} options={{
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Exercise Types'
              }
            },
            y: {
              stacked: false,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Cost in USD'
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Cost Usage and Limits by User Type'
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return `${context.dataset.label}: ${formatCost(context.raw)}`;
                }
              }
            }
          }
        }} />

        {/* Table */}
        <div className="table-responsive mt-4">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Section</th>
                <th colSpan="4" className="text-center">Basic</th>
                <th colSpan="4" className="text-center">Premium</th>
                <th colSpan="4" className="text-center">Admin</th>
              </tr>
              <tr>
                <th></th>
                <th>Avg</th>
                <th>Max</th>
                <th>Avg Limit</th>
                <th>Max Limit</th>
                <th>Avg</th>
                <th>Max</th>
                <th>Avg Limit</th>
                <th>Max Limit</th>
                <th>Avg</th>
                <th>Max</th>
                <th>Avg Limit</th>
                <th>Max Limit</th>
              </tr>
            </thead>
            <tbody>
              {sections.map(section => {
                const sectionData = metrics.metricsBySection[section] || {};
                const maxCost = getMaxCostPerExercise(section);
                const avgCost = getAvgCostPerExercise(section);
                
                return (
                  <tr key={section}>
                    <td className="text-capitalize">{section}</td>
                    {/* Basic */}
                    <td>{formatCost(sectionData.basicUserCount 
                      ? (sectionData.basicUserCount / (metrics.userMetrics.total - metrics.userMetrics.premium - metrics.userMetrics.admin)) * avgCost
                      : 0)}</td>
                    <td>{formatCost((sectionData.basicMaxUsage || 0) * avgCost)}</td>
                    <td>{formatCost(metrics.exerciseLimits.basic[section] * avgCost)}</td>
                    <td>{formatCost(metrics.exerciseLimits.basic[section] * maxCost)}</td>
                    {/* Premium */}
                    <td>{formatCost(sectionData.premiumUserCount
                      ? (sectionData.premiumUserCount / metrics.userMetrics.premium) * avgCost
                      : 0)}</td>
                    <td>{formatCost((sectionData.premiumMaxUsage || 0) * avgCost)}</td>
                    <td>{formatCost(metrics.exerciseLimits.premium[section] * avgCost)}</td>
                    <td>{formatCost(metrics.exerciseLimits.premium[section] * maxCost)}</td>
                    {/* Admin */}
                    <td>{formatCost(sectionData.adminUserCount
                      ? (sectionData.adminUserCount / metrics.userMetrics.admin) * avgCost
                      : 0)}</td>
                    <td>{formatCost((sectionData.adminMaxUsage || 0) * avgCost)}</td>
                    <td>{formatCost(metrics.exerciseLimits.admin[section] * avgCost)}</td>
                    <td>{formatCost(metrics.exerciseLimits.admin[section] * maxCost)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const UserStatsCard = ({ metrics }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">User Statistics</h2>
        <div className="row g-4">
          <div className="col-md-4">
            <div className="border rounded p-3 text-center">
              <div className="text-muted small">Total Users</div>
              <div className="h4 mb-0">{metrics.userMetrics.total}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border rounded p-3 text-center">
              <div className="text-muted small">Active Users</div>
              <div className="h4 mb-0">{metrics.userMetrics.active}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border rounded p-3 text-center">
              <div className="text-muted small">Premium Users</div>
              <div className="h4 mb-0">{metrics.userMetrics.premium}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TotalUsageCostsCard = ({ metrics }) => {
  // Filter out the 'total' section before calculating costs
  const sectionMetrics = Object.entries(metrics.metricsBySection)
    .filter(([section]) => section !== 'total')
    .map(([_, data]) => data);

  const totalCost = sectionMetrics
    .reduce((sum, m) => sum + 
      m.costs.claudeExerciseInput + 
      m.costs.claudeExerciseOutput + 
      m.costs.claudeFeedbackInput + 
      m.costs.claudeFeedbackOutput + 
      m.costs.whisper + 
      m.costs.tts, 0);

  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Total Usage & Costs</h2>
        <div className="row g-4">
          {/* Exercise Usage & Costs */}
          <div className="col-md-6">
            <div className="border rounded p-3">
              <h3 className="h6 mb-3">Exercise Usage & Costs</h3>
              <div className="mb-2">
                <div className="text-muted small">Input Tokens</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5 mb-0">
                    {formatNumber(sectionMetrics
                      .reduce((sum, m) => sum + m.exerciseInputTokens, 0))}
                  </div>
                  <div className="text-muted">
                    {formatCost(sectionMetrics
                      .reduce((sum, m) => sum + m.costs.claudeExerciseInput, 0))}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="text-muted small">Output Tokens</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5 mb-0">
                    {formatNumber(sectionMetrics
                      .reduce((sum, m) => sum + m.exerciseOutputTokens, 0))}
                  </div>
                  <div className="text-muted">
                    {formatCost(sectionMetrics
                      .reduce((sum, m) => sum + m.costs.claudeExerciseOutput, 0))}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="text-muted small">Text-to-Speech Characters</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5 mb-0">
                    {formatNumber(sectionMetrics
                      .reduce((sum, m) => sum + m.ttsCharacters, 0))}
                  </div>
                  <div className="text-muted">
                    {formatCost(sectionMetrics
                      .reduce((sum, m) => sum + m.costs.tts, 0))}
                  </div>
                </div>
              </div>
              <div className="mt-3 pt-2 border-top">
                <div className="text-muted small">Total Exercise Cost</div>
                <div className="h5 mb-0 text-end">
                  {formatCost(sectionMetrics
                    .reduce((sum, m) => sum + 
                      m.costs.claudeExerciseInput + 
                      m.costs.claudeExerciseOutput + 
                      m.costs.tts, 0))}
                </div>
              </div>
            </div>
          </div>

          {/* Feedback Usage & Costs */}
          <div className="col-md-6">
            <div className="border rounded p-3">
              <h3 className="h6 mb-3">Feedback Usage & Costs</h3>
              <div className="mb-2">
                <div className="text-muted small">Input Tokens</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5 mb-0">
                    {formatNumber(sectionMetrics
                      .reduce((sum, m) => sum + m.feedbackInputTokens, 0))}
                  </div>
                  <div className="text-muted">
                    {formatCost(sectionMetrics
                      .reduce((sum, m) => sum + m.costs.claudeFeedbackInput, 0))}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="text-muted small">Output Tokens</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5 mb-0">
                    {formatNumber(sectionMetrics
                      .reduce((sum, m) => sum + m.feedbackOutputTokens, 0))}
                  </div>
                  <div className="text-muted">
                    {formatCost(sectionMetrics
                      .reduce((sum, m) => sum + m.costs.claudeFeedbackOutput, 0))}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="text-muted small">Speech-to-Text Minutes</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5 mb-0">
                    {formatNumber((sectionMetrics
                      .reduce((sum, m) => sum + m.sttDuration, 0) / 60).toFixed(1))}
                  </div>
                  <div className="text-muted">
                    {formatCost(sectionMetrics
                      .reduce((sum, m) => sum + m.costs.whisper, 0))}
                  </div>
                </div>
              </div>
              <div className="mt-3 pt-2 border-top">
                <div className="text-muted small">Total Feedback Cost</div>
                <div className="h5 mb-0 text-end">
                  {formatCost(sectionMetrics
                    .reduce((sum, m) => sum + 
                      m.costs.claudeFeedbackInput + 
                      m.costs.claudeFeedbackOutput + 
                      m.costs.whisper, 0))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add Total Cost section */}
        <div className="mt-4 pt-3 border-top">
          <div className="d-flex justify-content-between align-items-center">
            <div className="h5 mb-0">Total Cost</div>
            <div className="h4 mb-0">{formatCost(totalCost)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AverageCostCard = ({ metrics }) => {
  const sections = ['reading', 'listening', 'writing', 'speaking'];
  
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Average Cost per Exercise</h2>
        
        {/* Average Cost Distribution Chart */}
        <div className="mb-4">
          <Bar
            data={{
              labels: sections,
              datasets: [
                {
                  label: 'Exercise Input',
                  data: sections.map(section => metrics.metricsBySection[section].averageCosts.claudeExerciseInput),
                  backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
                {
                  label: 'Exercise Output',
                  data: sections.map(section => metrics.metricsBySection[section].averageCosts.claudeExerciseOutput),
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
                {
                  label: 'Feedback Input',
                  data: sections.map(section => metrics.metricsBySection[section].averageCosts.claudeFeedbackInput),
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                  label: 'Feedback Output',
                  data: sections.map(section => metrics.metricsBySection[section].averageCosts.claudeFeedbackOutput),
                  backgroundColor: 'rgba(153, 102, 255, 0.5)',
                },
                {
                  label: 'Whisper',
                  data: sections.map(section => metrics.metricsBySection[section].averageCosts.whisper),
                  backgroundColor: 'rgba(255, 159, 64, 0.5)',
                },
                {
                  label: 'TTS',
                  data: sections.map(section => metrics.metricsBySection[section].averageCosts.tts),
                  backgroundColor: 'rgba(255, 205, 86, 0.5)',
                },
              ],
            }}
            options={{
              responsive: true,
              scales: {
                x: { stacked: true },
                y: { 
                  stacked: true,
                  title: {
                    display: true,
                    text: 'Cost in USD'
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `${context.dataset.label}: ${formatCost(context.raw)}`;
                    }
                  }
                }
              }
            }}
          />
        </div>

        {/* Average Cost Table */}
        <div className="table-responsive">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Section</th>
                <th>Exercise Input</th>
                <th>Exercise Output</th>
                <th>Feedback Input</th>
                <th>Feedback Output</th>
                <th>Whisper</th>
                <th>TTS</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {sections.map(section => {
                const sectionData = metrics.metricsBySection[section];
                return (
                  <tr key={section}>
                    <td className="text-capitalize">{section}</td>
                    <td>{formatCost(sectionData.averageCosts.claudeExerciseInput)}</td>
                    <td>{formatCost(sectionData.averageCosts.claudeExerciseOutput)}</td>
                    <td>{formatCost(sectionData.averageCosts.claudeFeedbackInput)}</td>
                    <td>{formatCost(sectionData.averageCosts.claudeFeedbackOutput)}</td>
                    <td>{formatCost(sectionData.averageCosts.whisper)}</td>
                    <td>{formatCost(sectionData.averageCosts.tts)}</td>
                    <td>{formatCost(sectionData.averageCost)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const MaxCostCard = ({ metrics }) => {
  const sections = ['reading', 'listening', 'writing', 'speaking'];
  
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Maximum Cost per Exercise</h2>
        
        {/* Maximum Cost Distribution Chart */}
        <div className="mb-4">
          <Bar
            data={{
              labels: sections,
              datasets: [
                {
                  label: 'Exercise Input',
                  data: sections.map(section => metrics.metricsBySection[section].maxCosts.claudeExerciseInput),
                  backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
                {
                  label: 'Exercise Output',
                  data: sections.map(section => metrics.metricsBySection[section].maxCosts.claudeExerciseOutput),
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
                {
                  label: 'Feedback Input',
                  data: sections.map(section => metrics.metricsBySection[section].maxCosts.claudeFeedbackInput),
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                  label: 'Feedback Output',
                  data: sections.map(section => metrics.metricsBySection[section].maxCosts.claudeFeedbackOutput),
                  backgroundColor: 'rgba(153, 102, 255, 0.5)',
                },
                {
                  label: 'Whisper',
                  data: sections.map(section => metrics.metricsBySection[section].maxCosts.whisper),
                  backgroundColor: 'rgba(255, 159, 64, 0.5)',
                },
                {
                  label: 'TTS',
                  data: sections.map(section => metrics.metricsBySection[section].maxCosts.tts),
                  backgroundColor: 'rgba(255, 205, 86, 0.5)',
                },
              ],
            }}
            options={{
              responsive: true,
              scales: {
                x: { stacked: true },
                y: { 
                  stacked: true,
                  title: {
                    display: true,
                    text: 'Cost in USD'
                  }
                }
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return `${context.dataset.label}: ${formatCost(context.raw)}`;
                    }
                  }
                }
              }
            }}
          />
        </div>

        {/* Maximum Cost Table */}
        <div className="table-responsive">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Section</th>
                <th>Exercise Input</th>
                <th>Exercise Output</th>
                <th>Feedback Input</th>
                <th>Feedback Output</th>
                <th>Whisper</th>
                <th>TTS</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {sections.map(section => {
                const sectionData = metrics.metricsBySection[section];
                return (
                  <tr key={section}>
                    <td className="text-capitalize">{section}</td>
                    <td>{formatCost(sectionData.maxCosts.claudeExerciseInput)}</td>
                    <td>{formatCost(sectionData.maxCosts.claudeExerciseOutput)}</td>
                    <td>{formatCost(sectionData.maxCosts.claudeFeedbackInput)}</td>
                    <td>{formatCost(sectionData.maxCosts.claudeFeedbackOutput)}</td>
                    <td>{formatCost(sectionData.maxCosts.whisper)}</td>
                    <td>{formatCost(sectionData.maxCosts.tts)}</td>
                    <td>{formatCost(sectionData.maxCost)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const FinancialMetricsCard = ({ metrics }) => {
  // Calculate totals
  const totalRevenue = Object.values(metrics.revenueMetrics).reduce((sum, m) => sum + m.total, 0);
  const totalCosts = Object.values(metrics.costMetrics).reduce((sum, m) => sum + m.total, 0);
  const totalProfit = metrics.profitMetrics.total;

  // Prepare data for the chart
  const chartData = {
    labels: ['Basic', 'Premium', 'Admin', 'Total'],
    datasets: [
      {
        label: 'Revenue',
        data: [
          ...['basic', 'premium', 'admin'].map(role => metrics.revenueMetrics[role].total),
          totalRevenue
        ],
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      },
      {
        label: 'Costs',
        data: [
          ...['basic', 'premium', 'admin'].map(role => metrics.costMetrics[role].total),
          totalCosts
        ],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      },
      {
        label: 'Profit',
        data: [
          ...['basic', 'premium', 'admin'].map(role => metrics.profitMetrics[role]),
          totalProfit
        ],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Amount (USD)'
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${formatCost(context.raw)}`;
          }
        }
      },
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Financial Metrics by User Type'
      }
    }
  };

  return (
    <div className="card mb-4">
      <div className="card-body">
        <h2 className="card-title h5 mb-3">Financial Metrics</h2>
        
        {/* Chart */}
        <div className="mb-4">
          <Bar data={chartData} options={chartOptions} />
        </div>

        {/* Table */}
        <div className="table-responsive">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>User Type</th>
                <th>Users</th>
                <th>Revenue</th>
                <th>Costs</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {['basic', 'premium', 'admin'].map(role => {
                const userCount = metrics.revenueMetrics[role].userCount;
                const revenue = metrics.revenueMetrics[role].total;
                const costs = metrics.costMetrics[role].total;
                const profit = metrics.profitMetrics[role];

                return (
                  <tr key={role}>
                    <td>{role}</td>
                    <td>{userCount}</td>
                    <td>{formatCost(revenue)}</td>
                    <td>{formatCost(costs)}</td>
                    <td>{formatCost(profit)}</td>
                  </tr>
                );
              })}
              <tr>
                <td>Total</td>
                <td>{metrics.userMetrics.total}</td>
                <td>{formatCost(Object.values(metrics.revenueMetrics).reduce((sum, m) => sum + m.total, 0))}</td>
                <td>{formatCost(Object.values(metrics.costMetrics).reduce((sum, m) => sum + m.total, 0))}</td>
                <td>{formatCost(metrics.profitMetrics.total)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const AdminPage = () => {
  const { user } = useContext(AuthContext);
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/admin/metrics', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setMetrics(response.data);
      } catch (err) {
        setError(err.response?.data?.error || err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  if (user?.role !== 'admin') {
    return <Navigate to="/" replace />;
  }

  if (loading) return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <div className="text-center">Loading metrics...</div>
    </div>
  );

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <h1 className="h3 mb-4">Admin Dashboard</h1>
      
      {error && <ErrorMessage error={error} />}
      
      {!error && metrics && (
        <>
          <UserStatsCard metrics={metrics} />
          <TotalUsageCostsCard metrics={metrics} />
          <FinancialMetricsCard metrics={metrics} />
          <AverageCostCard metrics={metrics} />
          <MaxCostCard metrics={metrics} />
          <ExerciseLimitsCard metrics={metrics} />
          <UserCostsCard metrics={metrics} />
        </>
      )}
    </div>
  );
};

export default AdminPage;