const highlightCorrections = (text) => {
  const parts = text.split(/(<ins>.*?<\/ins>|<del>.*?<\/del>)/g); // Split at both <ins> and <del> tags
  return parts.map((part, index) => {
    if (part.startsWith("<ins>") && part.endsWith("</ins>")) {
      return (
        <>
          {" "}
          <ins key={index} style={{ color: "#006400" }}>
            {part.replace(/<\/?ins>/g, "")}
          </ins>
        </>
      );
    } else if (part.startsWith("<del>") && part.endsWith("</del>")) {
      return (
        <>
          {" "}
          <del key={index} style={{ color: "#b22222" }}>
            {part.replace(/<\/?del>/g, "")}
          </del>
        </>
      );
    }
    return part; // Return the plain text if no tags
  });
};

export default highlightCorrections;
