import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import styles from "../styles/SupportButton.module.css"; // Importing the CSS module

const SupportButton = () => {
  const { t } = useTranslation(); // Initialize translation function
  const { user } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [supportText, setSupportText] = useState("");
  const [supportStatus, setSupportStatus] = useState(null); // For displaying success/error messages
  const [emailInput, setEmailInput] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
    setSupportStatus(null); // Reset status when opening modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (supportStatus === "success") {
      setSupportText(""); // Reset support text only after successful submission
    }
  };

  const handleSupportChange = (event) => {
    setSupportText(event.target.value);
  };

  const submitSupport = async () => {
    const trimmedMessage = supportText.trim();
    
    // Check for empty message
    if (!trimmedMessage) {
      setSupportStatus("empty");
      return;
    }

    // Check message length
    if (trimmedMessage.length < 10) {
      setSupportStatus("tooShort");
      return;
    }

    // Check maximum length
    if (trimmedMessage.length > 1000) {
      setSupportStatus("tooLong");
      return;
    }

    // Check email format for anonymous users
    if (!user && (!emailInput || !emailInput.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))) {
      setSupportStatus("invalidEmail");
      return;
    }

    try {
      const endpoint = user 
        ? '/api/support/authenticated'
        : '/api/support/anonymous';

      const payload = {
        message: trimmedMessage,
        ...(user ? {} : { email: emailInput })
      };

      const headers = user 
        ? { Authorization: `Bearer ${localStorage.getItem('token')}` }
        : {};

      const response = await axios.post(endpoint, payload, { headers });

      if (response.status === 200) {
        setSupportStatus("success");
        setSupportText("");
        if (!user) {
          setEmailInput(""); // Clear email input on success for anonymous users
        }
      }
    } catch (error) {
      console.error('Error submitting support:', error);
      setSupportStatus("error");
    }
  };

  return (
    <>
      <button className={`btn btn-primary ${styles.supportButton}`} onClick={openModal}>
        <i className="bi bi-headset me-2"></i>{t('support_button')}
      </button>

      {isModalOpen && (
        <div className={`modal fade show d-block ${styles.modalBackdrop}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('support_title')}</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {supportStatus !== "success" ? (
                  <>
                    {!user && (
                      <div className="mb-3">
                        <input
                          type="email"
                          className={`form-control ${emailInput && !emailInput.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? 'is-invalid' : ''}`}
                          placeholder={t('email_placeholder')}
                          value={emailInput}
                          onChange={(e) => setEmailInput(e.target.value)}
                          required
                          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        />
                        {emailInput && !emailInput.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && (
                          <div className="invalid-feedback">
                            {t('invalid_email_format')}
                          </div>
                        )}
                      </div>
                    )}
                    <textarea
                      className="form-control"
                      value={supportText}
                      onChange={handleSupportChange}
                      placeholder={t('support_placeholder')}
                      rows="5"
                    ></textarea>
                  </>
                ) : (
                  <div className="alert alert-success m-0" role="alert">
                    {t('support_success_message')}
                  </div>
                )}
                {supportStatus === "error" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('support_error_message')}
                  </div>
                )}
                {supportStatus === "empty" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('support_empty_message')}
                  </div>
                )}
                {supportStatus === "tooShort" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('support_too_short_message')}
                  </div>
                )}
                {supportStatus === "tooLong" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('support_too_long_message')}
                  </div>
                )}
                {supportStatus === "invalidEmail" && (
                  <div className="alert alert-danger mt-3 mb-0" role="alert">
                    {t('invalid_email_format')}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                  {t('close_button')}
                </button>
                {supportStatus !== "success" && (
                  <button type="button" className="btn btn-primary" onClick={submitSupport}>
                    {t('submit_button')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportButton;
