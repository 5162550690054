import React from "react";

const Passage = ({ title, passage, highlight }) => {
  const highlightText = (text, highlight) => {
    if (!highlight) {
      return text.split('\n').map((part, index) => (
        <React.Fragment key={index}>
          {part}
          <br />
        </React.Fragment>
      ));
    }
    
    const regex = new RegExp(`(${highlight})`, "gi");
    return text.split('\n').map((part, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {part.split(regex).map((subPart, index) =>
          regex.test(subPart) ? <mark key={index}>{subPart}</mark> : subPart
        )}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="main-text mb-3 alert alert-light" style={{ textAlign: "justify" }}>
      {/* Render the title */}
      {title && <div className="fs-4 mb-3 text-center">{title}</div>}
      
      {/* Render the content with highlight and line breaks */}
      {passage && highlightText(passage, highlight)}
    </div>
  );
};

export default Passage;
