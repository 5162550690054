import Stars from "./Stars";
import { useTranslation } from "react-i18next";

const Score = ({ score }) => {
  const { t } = useTranslation();
  
  let feedbackMessage;
  if (score === 1) {
    feedbackMessage = t('excellent_job');
  } else if (score >= 0.8) {
    feedbackMessage = t('great_job');
  } else if (score >= 0.6) {
    feedbackMessage = t('good_effort');
  } else {
    feedbackMessage = t('keep_practicing');
  }

  return (
    <div className='text-center'>
      <div className='fs-4'>{feedbackMessage}</div>
      <div className="fs-3 text-warning">
        <Stars score={score} />
      </div>
    </div>
  );
};

export default Score;
