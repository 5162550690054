import React, { useRef, useEffect } from "react";

const WritingArea = ({ value, onChange }) => {
  const editableDivRef = useRef(null);

  useEffect(() => {
    // Only update the innerHTML if the value has changed (avoiding unnecessary re-renders)
    const formattedValue = value
      .replace(/\n/g, '<br>')
      .replace(/<ins>/g, '<ins style="color: #006400">')
      .replace(/<del>/g, '<del style="color: #b22222">');

    if (editableDivRef.current && editableDivRef.current.innerHTML !== formattedValue) {
      editableDivRef.current.innerHTML = formattedValue;
    }
  }, [value]);

  const handleInputChange = () => {
    if (editableDivRef.current) {
      // Extract the value from contentEditable and revert the styled <ins> and <del> back to original
      const plainText = editableDivRef.current.innerHTML
        .replace(/<br>/g, '\n')
        .replace(/<ins style="color: #006400">/g, '<ins>')
        .replace(/<del style="color: #b22222">/g, '<del>');

      onChange(plainText);
    }
  };

  return (
    <div className="form-group mb-3">
      <div
        ref={editableDivRef}
        contentEditable
        className="form-control"
        style={{ minHeight: '200px', whiteSpace: 'pre-wrap' }}
        onInput={handleInputChange}
        aria-placeholder="Write your response here..."
      ></div>
    </div>
  );
};

export default WritingArea;
