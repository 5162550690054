import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const highlightText = (text, reference) => {
  if (!reference || !text) return text;

  // Escape special characters for RegExp
  const escapedReference = reference.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedReference})`, 'gi'));
  return parts.map((part, index) => 
    part.toLowerCase() === reference.toLowerCase() ? <mark key={index}>{part}</mark> : part
  );
};

const Transcript = ({ conversation, highlight }) => {
  const { t } = useTranslation();
  const [isTranscriptVisible, setTranscriptVisible] = useState(false);

  const toggleTranscriptVisibility = () => {
    setTranscriptVisible(!isTranscriptVisible);
  };

  // Return null if no conversation data yet
  if (!conversation || !Array.isArray(conversation)) {
    return null;
  }

  return (
    <div className="mb-4">
      <h5
        className="d-inline-block"
        style={{ cursor: 'pointer' }}
        onClick={toggleTranscriptVisibility}
        aria-expanded={isTranscriptVisible}
        aria-controls="transcriptCollapse"
      >
        {t('transcript')}
        <i className={`bi ${isTranscriptVisible ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
      </h5>
      <div id="transcriptCollapse" className={`collapse ${isTranscriptVisible ? 'show' : ''}`}>
        <div className="alert alert-light mt-2">
          {conversation.map((part, index) => {
            // Skip rendering if part isn't fully loaded
            if (!part?.speaker || !part?.text) return null;

            return (
              <div key={index}>
                <strong>{t('speaker')} {part.speaker}: </strong>
                {highlightText(part.text, highlight)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Transcript;
