import React from "react";

const HeadingsText = ({ passageTitle, passage, highlight, showResults }) => {
  // Return null if passage is not defined yet
  if (!passage) return null;

  // Ensure passage is an array, even if empty
  const passageArray = Array.isArray(passage) ? passage : [];

  return (
    <div className="alert alert-light">
      {/* Display title if provided */}
      {passageTitle && (
        <div className="fs-4 mb-3 text-center">{passageTitle}</div>
      )}

      {/* Display passage sections */}
      {passageArray.map((section, index) => {
        // Skip if section or text is undefined
        if (!section?.text) return null;

        // If the highlight matches, wrap the relevant part in <mark>, otherwise render plain text
        const highlightedText = showResults && highlight ? section.text.replace(
          highlight,
          `<mark>${highlight}</mark>`
        ) : section.text;

        return (
          <div 
            key={index} 
            className="d-flex"
            style={{ marginBottom: index === passageArray.length - 1 ? "0" : "1rem" }}
          >
            <div style={{ minWidth: '2em' }}>
              <strong>{String.fromCharCode(65 + index)}.</strong>
            </div>
            <div>
              <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeadingsText;
