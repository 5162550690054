import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Automatically import all translation files from the locales directory
const translations = {};
const importAllLocales = require.context('../locales', true, /\.json$/); // Look for all .json files in /locales

importAllLocales.keys().forEach((fileName) => {
  const locale = fileName.match(/\/([a-z]{2})\.json$/i)[1]; // Extract the language code from file name
  translations[locale] = { translation: importAllLocales(fileName) }; // Add the translations to the resources
});

i18n
  .use(initReactI18next)
  .init({
    resources: translations, // Use dynamically loaded translations
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
