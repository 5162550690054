import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure you have Bootstrap Icons included
import styles from '../styles/ExerciseTitle.module.css'; // Import the CSS module

const ExerciseTitle = ({ title, onRefresh }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleBack = () => {
    navigate('/home'); // Navigate to the home page
  };

  return (
    <div className="d-flex justify-content-center align-items-center mb-3">
      {/* Back icon to navigate to the home page */}
      <i
        className={`bi bi-reply ms-4 me-3 ${styles.icon}`} // Apply CSS module class
        onClick={handleBack}
        title="Go back to Home"
      ></i>
      
      <h1 className="text-center" style={{ margin: 0, verticalAlign: 'middle' }}>
        {title}
      </h1>
      
      {/* Refresh icon next to the title */}
      <i
        className={`bi bi-repeat ms-3 me-4 ${styles.icon}`} // Apply CSS module class
        onClick={onRefresh}
        title="Refresh Exercise"
      ></i>
    </div>
  );
};

export default ExerciseTitle;
