import React, { useState } from "react";
import Input from "./Input";
import checkAnswer from '../utils/checkAnswer';

// Helper function to check if all blanks are filled
const areAllBlanksFilled = (userAnswers, notes) => {
  if (!notes?.length) return false;
  return notes.every((note, index) => userAnswers[index]?.submitted);
};

// Helper function to calculate score
const calculateScore = (userAnswers, notes) => {
  if (!notes?.length) return 0;
  return notes.filter(
    (note, index) => userAnswers[index]?.isCorrect
  ).length / notes.length;
};

const NoteCompletion = ({ heading, notes, onComplete, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});

  const handleInputChange = (index, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  const handleSubmit = (index) => {
    const userAnswer = userAnswers[index]?.userAnswer;
    const noteObj = notes?.[index];

    if (userAnswer && noteObj) {
      const isCorrect = checkAnswer(userAnswer, noteObj.answer);

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [index]: { 
            userAnswer, 
            isCorrect, 
            submitted: true, 
            explanation: noteObj.explanation 
          },
        };

        onHighlightChange(noteObj.reference);

        if (areAllBlanksFilled(updatedAnswers, notes)) {
          const score = calculateScore(updatedAnswers, notes);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  const renderNotes = () => {
    // Return null if notes data isn't loaded yet
    if (!heading || !notes || !Array.isArray(notes)) {
      return null;
    }

    return (
      <div className="mb-5">
        <h4 className="text-center mb-4">{heading}</h4>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          {notes.map((note, index) => {
            // Skip incomplete notes
            if (!note?.text) return null;

            const userAnswer = userAnswers[index];

            return (
              <li key={index} className="mb-2" style={{ listStyleType: "disc" }}>
                <div style={{ display: "inline" }}>
                  {note.text.split('_').map((part, i, parts) => {
                    // If this isn't the last part, add an input field after it
                    if (i < parts.length - 1) {
                      return (
                        <React.Fragment key={i}>
                          <span>{part}</span>
                          <span className="mx-1" style={{ display: "inline-flex", alignItems: "center" }}>
                            <Input
                              userAnswer={userAnswer?.userAnswer}
                              onInputChange={(value) => handleInputChange(index, value)}
                              onSubmit={() => handleSubmit(index)}
                              isSubmitted={userAnswer?.submitted}
                              isCorrect={userAnswer?.isCorrect}
                              correctAnswer={note.answer}
                            />
                          </span>
                        </React.Fragment>
                      );
                    }
                    // Last part doesn't need an input field after it
                    return <span key={i}>{part}</span>;
                  })}
                </div>

                {userAnswer?.submitted && (
                  <div className="alert alert-warning mt-2">
                    {userAnswer.explanation}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // Return null if no data yet
  if (!heading || !notes) {
    return null;
  }

  return (
    <div>
      {renderNotes()}
    </div>
  );
};

export default NoteCompletion;