import React, { useState, useEffect, useRef } from "react";
import useStreamExercise from "../hooks/useStreamExercise";
import useStreamFeedback from "../hooks/useStreamFeedback";
import WritingArea from "./WritingArea";
import ErrorMessage from "./ErrorMessage";
import FeedbackWriting from "./FeedbackWriting";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Table from "./Table";
import SubmitButton from "./SubmitButton";
import Graph from "./Graph";
import Chart from "./Chart";
import ExerciseTitle from "./ExerciseTitle";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import Passage from "./Passage";
import ExerciseUsage from "./ExerciseUsage";
import ExerciseNavigation from "./ExerciseNavigation";
import { useNavigate } from 'react-router-dom';

const WritingExercise = ({ api }) => {
  const navigate = useNavigate();
  const { exercise, exerciseId, error: exerciseError, refetchExercise } = useStreamExercise(api);
  const { feedback, setFeedback, error: feedbackError, submitExercise } = useStreamFeedback(api);
  const [essayText, setEssayText] = useState('');
  const feedbackWasNull = useRef(true);
  const [resetTrigger, setResetTrigger] = useState(false);

  useEffect(() => {
    if (feedback !== null && feedbackWasNull.current) setResetTrigger((prev) => !prev);
    feedbackWasNull.current = feedback === null;
  }, [feedback]);

  const handleEssayChange = (text) => { setEssayText(text); };

  useEffect(() => {
    if (feedback?.correction) {
      setEssayText(feedback.correction);
    }
  }, [feedback?.correction]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("response", essayText);
    submitExercise(formData);
  };

  const handleRefresh = () => { setEssayText(''); setFeedback(null); refetchExercise(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="writing" style={{ marginBottom: '6px' }} trigger={resetTrigger} />
      {(exerciseError || feedbackError) && <ErrorMessage error={exerciseError || feedbackError} />}
      {!(exerciseError || feedbackError) && <>
        <Instructions instructions={exercise?.instructions} />
        {exercise?.prompt && <Prompt prompt={exercise.prompt} />}
        {exercise?.passage && <Passage
          title={exercise.passage.title} 
          passage={exercise.passage.content} />}
        {exercise?.table && <Table table={exercise.table} />}
        {exercise?.graph && <Graph graph={exercise.graph} />}
        {exercise?.chart && <Chart chart={exercise.chart} />}
        <WritingArea value={essayText} onChange={handleEssayChange} />
        <SubmitButton onClick={handleSubmit} isSubmitting={false} />
        {feedback && <FeedbackWriting feedback={feedback} />}
        <ExerciseNavigation onBack={() => navigate('/home')} onNext={handleRefresh} />
      </>}
    </div>
  );
};

export default WritingExercise;
