function checkAnswer(input1, input2) {
  if (!input1 || !input2) return false;

  try {
    // Convert to strings and normalize
    const str1 = normalizeString(input1);
    const str2 = normalizeString(input2);
    
    return str1 === str2;
  } catch (error) {
    console.warn('Error in checkAnswer:', error);
    return false;
  }
}

function normalizeString(input) {
  // Convert to string and lowercase
  let str = typeof input === 'object' ? JSON.stringify(input) : String(input).toLowerCase();

  // Define number words mapping
  const numberWords = {
    'one': '1', 'two': '2', 'three': '3', 'four': '4', 'five': '5',
    'six': '6', 'seven': '7', 'eight': '8', 'nine': '9', 'ten': '10',
    'eleven': '11', 'twelve': '12', 'thirteen': '13', 'fourteen': '14', 'fifteen': '15',
    'sixteen': '16', 'seventeen': '17', 'eighteen': '18', 'nineteen': '19', 'twenty': '20',
    'thirty': '30', 'forty': '40', 'fifty': '50', 'sixty': '60', 'seventy': '70',
    'eighty': '80', 'ninety': '90'
  };

  // Convert number words to digits first
  for (const [word, digit] of Object.entries(numberWords)) {
    str = str.replace(new RegExp(`\\b${word}\\b`, 'gi'), digit);
  }

  // Handle compound numbers (both word and digit forms)
  str = str
    .replace(/(\d\d)[\s-](\d)(?!\d)/g, (match, tens, ones) => {
      const tensNum = parseInt(tens);
      const onesNum = parseInt(ones);
      if (tensNum >= 20 && tensNum <= 90 && tensNum % 10 === 0) {
        return `${tensNum/10}${ones}`;
      }
      return match;
    });

  // Handle complex number phrases
  str = str
    // Handle millions with thousands
    .replace(/(\d+)\s+million\s+(\d+)\s+hundred(?:\s+thousand)?(?:\s+and\s+(\d+))?/gi, (match, millions, hundreds, rest) => {
      return millions + hundreds + (rest || '').padEnd(5, '0');
    })
    .replace(/(\d+)\s+million\s+(\d+)\s+thousand/gi, (match, millions, thousands) => {
      return millions + thousands.padStart(3, '0') + '000';
    })
    .replace(/(\d+)\s+million/gi, '$1000000')
    .replace(/million/gi, '1000000')
    
    // Handle thousands
    .replace(/(\d+)\s+thousand\s+(\d+)\s+hundred(?:\s+and\s+(\d+))?/gi, (match, thousands, hundreds, rest) => {
      return thousands + hundreds + (rest || '00');
    })
    .replace(/(\d+)\s+thousand\s+and\s+(\d+)/gi, (match, thousands, rest) => {
      return thousands + rest.padStart(3, '0');
    })
    .replace(/(\d+)\s+thousand\s+(\d+)/gi, (match, thousands, rest) => {
      return thousands + rest.padStart(3, '0');
    })
    .replace(/(\d+)\s+thousand/gi, '$1000')
    .replace(/thousand/gi, '1000')
    
    // Handle hundreds
    .replace(/(\d+)\s+hundred\s+and\s+(\d+)/gi, (match, hundreds, rest) => {
      return hundreds + rest.padStart(2, '0');
    })
    .replace(/hundred\s+and\s+(\d+)/gi, (match, rest) => {
      return '1' + rest.padStart(2, '0');
    })
    .replace(/(\d+)\s+hundred/gi, '$100')
    .replace(/hundred/gi, '100');

  // Convert time formats (e.g., "9 30" to "9:30")
  str = str.replace(/(\d+)\s+(\d+)(?=\s*(?:am|pm))/gi, '$1:$2');

  // Normalize AM/PM variations
  str = str
    .replace(/\b(a\.m\.?|a\.m|am\.?|a m|am)\b/gi, 'am')
    .replace(/\b(p\.m\.?|p\.m|pm\.?|p m|pm)\b/gi, 'pm')
    .replace(/(\d)\s*(am|pm)/gi, '$1$2')
    .replace(/\.*$/, '');

  return str.replace(/\s+/g, ' ').trim();
}

export default checkAnswer;
