import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { updateKnownLanguage } from '../services/userService';
import i18n from 'i18next';

const LanguageSelect = ({ disabled = false }) => {
  const { user, setUser } = useContext(AuthContext);
  const [knownLanguage, setKnownLanguage] = useState('EN'); // Default to English
  const [loading, setLoading] = useState(false);

  // List of supported languages
  const supportedLanguages = [
    { code: "AF", name: "Afrikaans" },
    { code: "AR", name: "العربية" }, // Arabic
    { code: "HY", name: "Հայերեն" }, // Armenian
    { code: "AZ", name: "Azərbaycanca" }, // Azerbaijani
    { code: "BE", name: "Беларуская" }, // Belarusian
    { code: "BS", name: "Bosanski" }, // Bosnian
    { code: "BG", name: "Български" }, // Bulgarian
    { code: "CA", name: "Català" }, // Catalan
    { code: "ZH", name: "中文" }, // Chinese
    { code: "HR", name: "Hrvatski" }, // Croatian
    { code: "CS", name: "Čeština" }, // Czech
    { code: "DA", name: "Dansk" }, // Danish
    { code: "NL", name: "Nederlands" }, // Dutch
    { code: "EN", name: "English" }, // English
    { code: "ET", name: "Eesti" }, // Estonian
    { code: "FI", name: "Suomi" }, // Finnish
    { code: "FR", name: "Français" }, // French
    { code: "GL", name: "Galego" }, // Galician
    { code: "DE", name: "Deutsch" }, // German
    { code: "EL", name: "Ελληνικά" }, // Greek
    { code: "HE", name: "עברית" }, // Hebrew
    { code: "HI", name: "हिन्दी" }, // Hindi
    { code: "HU", name: "Magyar" }, // Hungarian
    { code: "IS", name: "Íslenska" }, // Icelandic
    { code: "ID", name: "Bahasa Indonesia" }, // Indonesian
    { code: "IT", name: "Italiano" }, // Italian
    { code: "JA", name: "日本語" }, // Japanese
    { code: "KN", name: "ಕನ್ನಡ" }, // Kannada
    { code: "KK", name: "Қазақша" }, // Kazakh
    { code: "KO", name: "한국어" }, // Korean
    { code: "LV", name: "Latviešu" }, // Latvian
    { code: "LT", name: "Lietuvių" }, // Lithuanian
    { code: "MK", name: "Македонски" }, // Macedonian
    { code: "MS", name: "Bahasa Melayu" }, // Malay
    { code: "MR", name: "मराठी" }, // Marathi
    { code: "MI", name: "Māori" }, // Maori
    { code: "NE", name: "नेपाली" }, // Nepali
    { code: "NO", name: "Norsk" }, // Norwegian
    { code: "FA", name: "فارسی" }, // Persian
    { code: "PL", name: "Polski" }, // Polish
    { code: "PT", name: "Português" }, // Portuguese
    { code: "RO", name: "Română" }, // Romanian
    { code: "RU", name: "Русский" }, // Russian
    { code: "SR", name: "Српски" }, // Serbian
    { code: "SK", name: "Slovenčina" }, // Slovak
    { code: "SL", name: "Slovenščina" }, // Slovenian
    { code: "ES", name: "Español" }, // Spanish
    { code: "SW", name: "Kiswahili" }, // Swahili
    { code: "SV", name: "Svenska" }, // Swedish
    { code: "TL", name: "Tagalog" }, // Tagalog
    { code: "TA", name: "தமிழ்" }, // Tamil
    { code: "TH", name: "ไทย" }, // Thai
    { code: "TR", name: "Türkçe" }, // Turkish
    { code: "UK", name: "Українська" }, // Ukrainian
    { code: "UR", name: "اردو" }, // Urdu
    { code: "VI", name: "Tiếng Việt" }, // Vietnamese
    { code: "CY", name: "Cymraeg" }, // Welsh
  ];

  // Sort languages alphabetically by their native name
  supportedLanguages.sort((a, b) => a.name.localeCompare(b.name, 'default', { sensitivity: 'base' }));  

  // Extract the supported language codes for easy comparison
  const supportedLanguageCodes = supportedLanguages.map(lang => lang.code);

  // Function to detect browser language and set it
  const detectBrowserLanguage = () => {
    const browserLanguage = navigator.language || navigator.languages[0]; // Get browser's preferred language
    const languageCode = browserLanguage.split('-')[0].toUpperCase(); // Extract the language code, e.g., 'en' from 'en-US'
    // Check if the detected language is supported, otherwise default to 'EN'
    if (supportedLanguageCodes.includes(languageCode)) {
      return languageCode;
    }
    return 'EN'; // Default to English if unsupported
  };

  useEffect(() => {
    // Initialize knownLanguage from user if logged in, or detect from browser on first visit if no localStorage value
    const storedLanguage = localStorage.getItem('language');
    const initialLanguage = user?.knownLanguage || storedLanguage || detectBrowserLanguage();

    // Set the detected or stored language
    setKnownLanguage(initialLanguage);

    // Change language in i18n to apply to UI
    i18n.changeLanguage(initialLanguage.toLowerCase());

    // If no language is in localStorage (first visit), store the detected language
    if (!storedLanguage) {
      localStorage.setItem('language', initialLanguage);
    }
  }, [user]);

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setLoading(true);

    try {
      // Update i18n for immediate translation change in the UI
      i18n.changeLanguage(newLanguage.toLowerCase());

      // Update localStorage for both guests and logged-in users
      localStorage.setItem('language', newLanguage);

      // If the user is logged in, update the backend and context
      if (user) {
        const token = localStorage.getItem('token');
        await updateKnownLanguage(newLanguage, token); // Update backend

        // Update the user context with the new language
        setUser((prevUser) => ({
          ...prevUser,
          knownLanguage: newLanguage,
        }));
      }

      // Set the new language in local state
      setKnownLanguage(newLanguage);
    } catch (error) {
      console.error("Error updating language:", error);
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <div className="form-group">
      <select
        id="knownLanguage"
        value={knownLanguage}
        onChange={handleLanguageChange}
        className="form-select"
        disabled={loading || disabled}
      >
        {supportedLanguages.map(lang => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelect;
