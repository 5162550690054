import React, { useState, useEffect } from "react";
import QuestionItem from "./QuestionItem";

const areAllQuestionsAnswered = (userAnswers, questions) => {
  return questions.every((_, index) => userAnswers[index] && userAnswers[index].userAnswer);
};

const calculateScore = (userAnswers, questions) => {
  return Object.values(userAnswers).filter(answer => answer.isCorrect).length / questions.length;
};

const shuffleArray = (array) => {
  if (!Array.isArray(array)) return [];
  
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const MultipleChoice = ({ questions, onHighlightChange, onComplete, shuffled = true }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [lastAnsweredIndex, setLastAnsweredIndex] = useState(null);
  const [shuffledOptions, setShuffledOptions] = useState({});

  useEffect(() => { 
    setShuffledOptions(prevShuffled => {
      const newShuffled = { ...prevShuffled };
      questions?.forEach((question, index) => {
        if (!newShuffled[index] && question?.answer && shuffled) {
          newShuffled[index] = shuffleArray(question.options);
        }
      });
      return newShuffled;
    });
  }, [questions, onHighlightChange, shuffled]);

  const handleOptionChange = (questionIndex, selectedOption) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = {
        ...prevAnswers,
        [questionIndex]: {
          userAnswer: selectedOption.userAnswer,
          isCorrect: selectedOption.isCorrect
        }
      };
      
      setLastAnsweredIndex(questionIndex);
      onHighlightChange(questions[questionIndex]?.reference || "");

      if (areAllQuestionsAnswered(newAnswers, questions)) {
        const score = calculateScore(newAnswers, questions);
        onComplete(score);
      }

      return newAnswers;
    });
  };

  const handleHoverChange = (questionIndex) => {
    if (userAnswers[questionIndex]) { onHighlightChange(questions[questionIndex]?.reference || ""); }
  };

  const handleMouseLeave = () => {
    if (lastAnsweredIndex !== null) { onHighlightChange(questions[lastAnsweredIndex]?.reference || ""); } 
    else { onHighlightChange(""); }
  };

  return (
    <div>
      {questions.map((question, index) => (
        <QuestionItem
          key={index}
          questionData={{
            ...question,
            options: (shuffled && shuffledOptions[index]) 
              ? shuffledOptions[index] 
              : question.options
          }}
          questionIndex={index}
          userAnswer={userAnswers[index]}
          onOptionChange={handleOptionChange}
          onHover={() => handleHoverChange(index)}
          onLeave={handleMouseLeave}
        />
      ))}
    </div>
  );
};

export default MultipleChoice;