import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../config/constants';
import { AuthContext } from '../context/AuthContext';

const TrialUsage = ({ style }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  
  // Calculate days remaining
  const trialEndDate = new Date(user.createdAt);
  trialEndDate.setDate(trialEndDate.getDate() + constants.trialDays);
  const now = new Date();
  const daysRemaining = Math.max(0, Math.ceil((trialEndDate - now) / (1000 * 60 * 60 * 24)));
  
  // Determine which message to show
  let message;
  if (daysRemaining === 0) {
    message = t('trial_expired');
  } else if (daysRemaining === 1) {
    message = t('trial_expires_today');
  } else {
    message = t('trial_days_remaining', { days: daysRemaining });
  }

  // Progress represents remaining time, but shows 100% when expired
  const progress = daysRemaining === 0 ? 100 : (daysRemaining / constants.trialDays) * 100;

  return (
    <div className="mt-2" style={style}>
      <div className="progress" style={{ height: '25px', position: 'relative' }}>
        <div
          className={`progress-bar ${daysRemaining <= 3 ? 'bg-danger' : 'bg-warning'} fs-6`}
          role="progressbar"
          style={{ width: `${progress}%`, color: daysRemaining === 0 ? '#fff' : (daysRemaining <= 3 ? '#dc3546' : '#333') }}
          aria-valuenow={daysRemaining}
          aria-valuemin="0"
          aria-valuemax={constants.trialDays}
        >
        </div>
        
        <span 
          style={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            textAlign: 'center',
            color: daysRemaining === 0 ? '#fff' : (daysRemaining <= 3 ? '#dc3546' : '#333'),
          }}
          className="fs-6"
        >
          {message}
        </span>
      </div>
    </div>
  );
};

export default TrialUsage;