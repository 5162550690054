import React from "react";

const QuestionItem = ({ questionData, questionIndex, userAnswer, onOptionChange, onHover, onLeave }) => {
  // Show partial question even if options aren't loaded yet
  if (!questionData) {
    return null;
  }

  const handleOptionClick = (option) => {
    const isCorrect = option === questionData.answer;
    onOptionChange(questionIndex, { userAnswer: option, isCorrect });
  };

  return (
    <div className="question-item mb-3" onMouseEnter={onHover} onMouseLeave={onLeave}>
      <div className="mb-3">
        <div className="me-2 mb-1">{questionIndex + 1}. {questionData.question || ''}</div>
        {questionData.options && (
          <div className="d-flex flex-column" style={{ gap: '0.2em' }}>
            {questionData.options.map((option, optIndex) => {
              let optionClass = "btn btn-outline-primary w-100 text-start";
              if (userAnswer) {
                if (option === questionData.answer) optionClass = "btn btn-success w-100 text-start";
                else if (option === userAnswer.userAnswer) optionClass = "btn btn-danger w-100 text-start";
              }
              return (
                <button
                  key={optIndex}
                  className={optionClass}
                  onClick={() => handleOptionClick(option)}
                  disabled={userAnswer !== undefined}
                >
                  {option}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {userAnswer && questionData.explanation && (
        <div className="alert alert-warning mt-2" role="alert">
          {questionData.explanation}
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
