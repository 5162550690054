import React from "react";

const Explanations = ({ explanations }) => {
  // Sort explanations by the "number" property
  const sortedExplanations = [...explanations].sort((a, b) => a.number - b.number);

  return (
    <div className="my-3 pt-3 pb-2 alert alert-warning">
      {sortedExplanations.map((explanation, index) => (
        <div key={index} className="mb-2 d-flex">
          <div style={{ minWidth: '1.5em' }}>
            <strong>{explanation.number}.</strong>
          </div>
          <div>{explanation.text}</div>
        </div>
      ))}
    </div>
  );
};

export default Explanations;
