const languages = [
  { code: "AF", name: "Afrikaans" },
  { code: "AR", name: "العربية" },
  { code: "HY", name: "Հայերեն" },
  { code: "AZ", name: "Azərbaycanca" },
  { code: "BE", name: "Беларуская" },
  { code: "BS", name: "Bosanski" },
  { code: "BG", name: "Български" },
  { code: "CA", name: "Català" },
  { code: "ZH", name: "中文" },
  { code: "HR", name: "Hrvatski" },
  { code: "CS", name: "Čeština" },
  { code: "DA", name: "Dansk" },
  { code: "NL", name: "Nederlands" },
  { code: "EN", name: "English" },
  { code: "ET", name: "Eesti" },
  { code: "FI", name: "Suomi" },
  { code: "FR", name: "Français" },
  { code: "GL", name: "Galego" },
  { code: "DE", name: "Deutsch" },
  { code: "EL", name: "Ελληνικά" },
  { code: "HE", name: "עברית" },
  { code: "HI", name: "हिन्दी" },
  { code: "HU", name: "Magyar" },
  { code: "IS", name: "Íslenska" },
  { code: "ID", name: "Bahasa Indonesia" },
  { code: "IT", name: "Italiano" },
  { code: "JA", name: "日本語" },
  { code: "KN", name: "ಕನ್ನಡ" },
  { code: "KK", name: "Қазақша" },
  { code: "KO", name: "한국어" },
  { code: "LV", name: "Latviešu" },
  { code: "LT", name: "Lietuvių" },
  { code: "MK", name: "Македонски" },
  { code: "MS", name: "Bahasa Melayu" },
  { code: "MR", name: "मराठी" },
  { code: "MI", name: "Māori" },
  { code: "NE", name: "नेपाली" },
  { code: "NO", name: "Norsk" },
  { code: "FA", name: "فارسی" },
  { code: "PL", name: "Polski" },
  { code: "PT", name: "Português" },
  { code: "RO", name: "Română" },
  { code: "RU", name: "Русский" },
  { code: "SR", name: "Српски" },
  { code: "SK", name: "Slovenčina" },
  { code: "SL", name: "Slovenščina" },
  { code: "ES", name: "Español" },
  { code: "SW", name: "Kiswahili" },
  { code: "SV", name: "Svenska" },
  { code: "TL", name: "Tagalog" },
  { code: "TA", name: "தமிழ்" },
  { code: "TH", name: "ไทย" },
  { code: "TR", name: "Türkçe" },
  { code: "UK", name: "Українська" },
  { code: "UR", name: "اردو" },
  { code: "VI", name: "Tiếng Việt" },
  { code: "CY", name: "Cymraeg" },
].sort((a, b) => a.name.localeCompare(b.name, 'default', { sensitivity: 'base' }));

export default languages;