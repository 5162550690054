import { useTranslation } from 'react-i18next';

const useExerciseTitleFromApi = (api) => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Extract the exercise category and type from the API string
  const [category, exerciseType] = api.split("/").slice(6, 8);

  // Translate the category and exercise type using i18next
  const translatedCategory = t(category); // e.g., "reading" -> "Reading"
  const translatedExerciseType = t(exerciseType); // e.g., "multiple_choice" -> "Multiple Choice"

  // Return the translated title in the format "Category / Exercise Type"
  return `${translatedCategory} / ${translatedExerciseType}`;
};

export default useExerciseTitleFromApi;
