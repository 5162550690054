import React, { useState, useRef, useEffect } from "react";
import ErrorMessage from "./ErrorMessage";
import FeedbackSpeaking from "./FeedbackSpeaking";
import AudioRecorder from "./AudioRecorder";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Image from "./Image";
import ExerciseTitle from "./ExerciseTitle";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import ExerciseUsage from "./ExerciseUsage";
import useStreamExercise from "../hooks/useStreamExercise";
import useStreamFeedback from "../hooks/useStreamFeedback";
import ExerciseNavigation from "./ExerciseNavigation";
import { useNavigate } from 'react-router-dom';
import CorrectedResponse from "./CorrectedResponse";

const SpeakingExercise = ({ api }) => {
  const navigate = useNavigate();
  const { exercise, exerciseId, error: exerciseError, refetchExercise } = useStreamExercise(api);
  const { feedback, setFeedback, error: feedbackError, submitExercise } = useStreamFeedback(api);
  const feedbackWasNull = useRef(true);
  const [resetTrigger, setResetTrigger] = useState(false);

  useEffect(() => {
    if (feedback !== null && feedbackWasNull.current) setResetTrigger((prev) => !prev);
    feedbackWasNull.current = feedback === null;
  }, [feedback]);

  const handleStopRecording = (blob) => {
    const formData = new FormData();
    formData.append("exerciseId", exerciseId);
    formData.append("audio", blob, `audio.${blob.type.split("/")[1]}`);
    submitExercise(formData);
  };

  const handleRefresh = () => { setFeedback(null); refetchExercise(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="speaking" style={{ marginBottom: '6px' }} trigger={resetTrigger} />
      {(exerciseError || feedbackError) && <ErrorMessage error={exerciseError || feedbackError} />}
      {!exerciseError && !feedbackError && <>
        <Instructions instructions={exercise?.instructions} />
        {exercise?.prompt && <Prompt prompt={exercise.prompt} />}
        {exercise?.imageUrl && <Image url={exercise.imageUrl} />}
        <AudioRecorder onStopRecording={handleStopRecording} isSubmitting={false} />
        {feedback && <>
          <CorrectedResponse text={feedback.correction} />
          <FeedbackSpeaking feedback={feedback} />
        </>}
        <ExerciseNavigation onBack={() => navigate('/home')} onNext={handleRefresh} />
      </>}
    </div>
  );
};

export default SpeakingExercise;
