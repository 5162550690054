import React, { useState } from 'react';
import HeadingsText from './HeadingsText';
import HeadingsList from './HeadingsList';

const MatchingHeadings = ({ 
  passageTitle, 
  passage, 
  headings, 
  onComplete 
}) => {
  const [highlight, setHighlight] = useState('');
  const [score, setScore] = useState(null);

  const handleComplete = (calculatedScore) => {
    setScore(calculatedScore);
    onComplete(calculatedScore);
  };

  return (
    <div>
      <HeadingsText 
        passageTitle={passageTitle}
        passage={passage} 
        highlight={highlight} 
        showResults={score !== null} 
      />
      <div className="mt-4">
        <HeadingsList
          passage={passage}
          headings={headings}
          onHighlightChange={setHighlight}
          onComplete={handleComplete}
          showResults={score !== null}
        />
      </div>
    </div>
  );
};

export default MatchingHeadings;