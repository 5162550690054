import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from '../styles/LoginPage.module.css'; // Import custom CSS module
import logo from '../assets/logo_with_ostrich.png'; // Import your logo
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import i18next

const LoginPage = () => {
  const { login, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(); // Initialize translation hook
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/home');
    }

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      const verifyEmail = async () => {
        try {
          const response = await axios.get(`/api/auth/verify-email?token=${token}`);
          setStatus(t('login_status_email_verified'));
        } catch (error) {
          setStatus(t('login_error_verification_failed'));
        }
      };
      verifyEmail();
    }
  }, [user, navigate, location.search, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/home');
    } catch (error) {
      setError(t('login_error_invalid_credentials'));
    }
  };

  return (
    <div className={`d-flex align-items-center py-4 bg-body-tertiary vh-100 ${styles.formContainer}`}>
      <main className={`form-signin w-100 m-auto ${styles.formSignin}`}>
        
        <form onSubmit={handleSubmit}>
          <img className="mb-4" src={logo} alt="Logo" width="110" />
          <h1 className="h3 mb-3 fw-normal">{t('login_page_title')}</h1>

          {status && (
            <div className="alert alert-success" role="alert">
              {status}
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder={t('login_email_label')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingInput">{t('login_email_label')}</label>
          </div>
          <div className={`form-floating ${styles.formFloating}`}>
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder={t('login_password_label')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">{t('login_password_label')}</label>
          </div>

          <button className="btn btn-primary w-100 py-2" type="submit">{t('login_button')}</button>
          <p className="mt-5 mb-3 text-body-secondary">© 2024 Fluentious</p>
        </form>
      </main>
    </div>
  );
};

export default LoginPage;
