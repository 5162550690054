import { useState, useRef, useEffect } from 'react';
import Instructions from './Instructions';
import Passage from './Passage';
import ExerciseTitle from './ExerciseTitle';
import useExerciseTitleFromApi from '../hooks/useExerciseTitleFromApi';
import useStreamExercise from '../hooks/useStreamExercise';
import ExerciseUsage from './ExerciseUsage';
import MultipleChoice from './MultipleChoice';
import GapFilling from './GapFilling';
import ErrorMessage from './ErrorMessage';
import MatchingHeadings from './MatchingHeadings';
import SentenceCompletion from './SentenceCompletion';
import TableCompletion from './TableCompletion';
import NoteCompletion from './NoteCompletion';
import SummaryCompletion from './SummaryCompletion';
import ShortAnswerQuestions from './ShortAnswerQuestions';
import useSubmitScore from '../hooks/useSubmitScore';
import Score from './Score';
import ExerciseNavigation from './ExerciseNavigation';
import { useNavigate } from 'react-router-dom';

function ReadingExercise({ api }) {
  const navigate = useNavigate();
  const { exercise, exerciseId, error, refetchExercise } = useStreamExercise(api);
  const [highlight, setHighlight] = useState('');
  const { score, setScore, submitScore } = useSubmitScore();
  const exerciseWasNull = useRef(true);
  const [resetTrigger, setResetTrigger] = useState(false);

  useEffect(() => {
    if (exercise !== null && exerciseWasNull.current) setResetTrigger((prev) => !prev);
    exerciseWasNull.current = exercise === null;
  }, [exercise]);

  const handleCompletion = async (calculatedScore) => { await submitScore(exerciseId, calculatedScore); };
  const handleRefresh = () => { setHighlight(''); setScore(null); refetchExercise(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="reading" style={{ marginBottom: '6px' }} trigger={resetTrigger} />
      {error && <ErrorMessage error={error} />}
      {!error && <>
        <Instructions instructions={exercise?.instructions} />
        {exercise?.passage && <Passage 
          title={exercise.passage.title} 
          passage={exercise.passage.content} 
          highlight={highlight} />}
        {exercise?.multiple_choice && <MultipleChoice
          questions={exercise.multiple_choice}
          onHighlightChange={setHighlight}
          onComplete={handleCompletion}
          shuffled={true} />}
        {exercise?.true_false_not_given && <MultipleChoice
          questions={exercise.true_false_not_given}
          onHighlightChange={setHighlight}
          onComplete={handleCompletion}
          shuffled={false} />}
        {exercise?.gap_filling && <GapFilling
          passageTitle={exercise.gap_filling.passage_title}
          passageSegments={exercise.gap_filling.passage || []}
          onComplete={handleCompletion} />}
        {exercise?.matching_headings && <MatchingHeadings
          passageTitle={exercise.matching_headings.passage_title}
          passage={exercise.matching_headings.passage}
          headings={exercise.matching_headings.headings}
          onComplete={handleCompletion} />}
        {exercise?.sentence_completion && <SentenceCompletion
          blanks={exercise.sentence_completion}
          onHighlightChange={setHighlight}
          onComplete={handleCompletion} />}
        {exercise?.table_completion && <TableCompletion
          table={exercise.table_completion.table}
          onHighlightChange={setHighlight}
          answers={exercise.table_completion.answers}
          onComplete={handleCompletion} />}
        {exercise?.note_completion && <NoteCompletion
          heading={exercise.note_completion.heading}
          notes={exercise.note_completion.notes}
          onComplete={handleCompletion}
          onHighlightChange={setHighlight} />}
        {exercise?.summary_completion && <SummaryCompletion
          summary={exercise.summary_completion}
          onComplete={handleCompletion}
          onHighlightChange={setHighlight} />}
        {exercise?.short_answer_questions && <ShortAnswerQuestions
          short_answer_questions={exercise.short_answer_questions}
          onComplete={handleCompletion}
          onHighlightChange={setHighlight} />}
      </>}
      {score !== null && <Score score={score} />}
      <ExerciseNavigation onBack={() => navigate('/home')} onNext={handleRefresh} />
    </div>
  );
}

export default ReadingExercise;
