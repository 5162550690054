import React, { useState } from "react";
import Input from "./Input";
import checkAnswer from '../utils/checkAnswer';

// Helper function to check if all answers are submitted
const areAllAnswersSubmitted = (userAnswers, questions) => {
  if (!questions?.length) return false;
  return questions.every((question, index) => 
    question?.answer && userAnswers[index]?.submitted
  );
};

// Helper function to calculate the score
const calculateScore = (userAnswers, questions) => {
  if (!questions?.length) return 0;
  const completedQuestions = questions.filter(q => q?.answer);
  return completedQuestions.filter(
    (_, index) => userAnswers[index]?.isCorrect
  ).length / completedQuestions.length;
};

const ShortAnswerQuestions = ({ short_answer_questions, onComplete, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});

  const handleInputChange = (index, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  const handleSubmit = (index) => {
    const userAnswer = userAnswers[index]?.userAnswer;
    const questionObj = short_answer_questions[index];

    if (userAnswer && questionObj?.answer) {
      const isCorrect = checkAnswer(userAnswer, questionObj.answer);

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [index]: {
            userAnswer,
            isCorrect,
            submitted: true,
            explanation: questionObj.explanation,
          },
        };

        onHighlightChange(questionObj.reference);

        if (areAllAnswersSubmitted(updatedAnswers, short_answer_questions)) {
          const score = calculateScore(updatedAnswers, short_answer_questions);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  const renderQuestions = () => {
    // Return null if questions array isn't loaded yet
    if (!Array.isArray(short_answer_questions)) {
      return null;
    }

    return (
      <div className="mb-5">
        <ul style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
          {short_answer_questions.map((questionObj, index) => {
            // Skip rendering if question isn't fully loaded
            if (!questionObj?.question) return null;

            return (
              <li key={index} className="mb-3">
                <div>
                  <span>{questionObj.question} </span>
                  {/* Only show input field if the answer is loaded */}
                  {questionObj.answer && (
                    <Input
                      userAnswer={userAnswers[index]?.userAnswer}
                      onInputChange={(value) => handleInputChange(index, value)}
                      onSubmit={() => handleSubmit(index)}
                      isSubmitted={userAnswers[index]?.submitted}
                      isCorrect={userAnswers[index]?.isCorrect}
                      correctAnswer={questionObj.answer}
                    />
                  )}
                </div>
                {userAnswers[index]?.submitted && (
                  <div className="alert alert-warning mt-2">
                    {userAnswers[index].explanation}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // Return null if no questions data yet
  if (!short_answer_questions) {
    return null;
  }

  return <div>{renderQuestions()}</div>;
};

export default ShortAnswerQuestions;