import React from "react";

const Input = ({ userAnswer, onInputChange, onSubmit, isSubmitted, isCorrect, correctAnswer }) => {
  // Handle key press for submitting on "Enter"
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      {isSubmitted ? (
        <div className="btn-group d-inline-block mx-2" role="group" style={{ marginTop: "1px", marginBottom: "1px" }}>
          <button className={`btn ${isCorrect ? "btn-success" : "btn-danger"}`} disabled>
            {userAnswer}
          </button>
          {!isCorrect && (
            <button className="btn btn-success" disabled>
              {correctAnswer}
            </button>
          )}
        </div>
      ) : (
        <div className="mx-1 d-flex align-items-center" style={{ marginTop: "1px", marginBottom: "1px" }}>
          <input
            type="text"
            className="form-control"
            style={{ 
              width: "150px",
              borderRight: "none",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onChange={(e) => onInputChange(e.target.value)}
            onKeyDown={handleKeyPress}
            value={userAnswer || ""}
          />
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            style={{ 
              height: "38px",
              width: "35px",
              padding: 0,
              marginLeft: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }}
          >
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      )}
    </span>
  );
};

export default Input;
