import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const VerifyEmailPage = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get('token');
      if (token) {
        try {
          const response = await axios.get(`/api/auth/verify-email?token=${token}`);
          setStatus(response.data.message);
        } catch (error) {
          setStatus(error.response?.data?.message || 'Failed to verify email. Please try again.');
        }
      } else {
        setStatus('Invalid token');
      }
    };
    verifyEmail();
  }, [searchParams]);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.heading}>Email Verification</h2>
        <p style={styles.message}>{status}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px 40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  heading: {
    color: '#002255',
    marginBottom: '20px',
  },
  message: {
    fontSize: '16px',
    color: '#333',
  },
};

export default VerifyEmailPage;
