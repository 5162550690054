const constants = {
  trialDays: 14,
  exerciseLimits: {
    basic: {
      reading: 10,
      listening: 10,
      writing: 10,
      speaking: 10
    },
    premium: {
      reading: 100,
      listening: 50,
      writing: 65,
      speaking: 65
    },
    admin: {
      reading: 100,
      listening: 100,
      writing: 100,
      speaking: 100
    }
  },
  prices: {
    standard: '$14.99',
    discounted: '$11.99',
    discount: 20
  }
};

export default constants;