import React from 'react';
import styles from '../styles/TableCompletion.module.css';

const Table = ({ table }) => {
  if (!table) {
    return null;
  }

  return (
    <div className={styles.tableContainer}>
      {table.title && (
        <div className="text-center fs-5 mb-2" style={{ color: 'black' }}>
          {table.title}
        </div>
      )}
      
      <table className={`table ${styles.roundedTable}`}>
        {table.columns && Array.isArray(table.columns) && (
          <thead>
            <tr>
              {table.columns.map((column, index) => (
                <th key={index}>{column || ''}</th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {table.rows && Array.isArray(table.rows) && table.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Array.isArray(row) && row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell || ''}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
