import { useState, useEffect, useCallback } from 'react';
import { jsonrepair } from 'jsonrepair';

const useStreamExercise = (api) => {
  const [rawExercise, setRawExercise] = useState('');
  const [exercise, setExercise] = useState(null);
  const [exerciseId, setExerciseId] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  const refetchExercise = useCallback(() => {
    setRefetchTrigger(prev => prev + 1);
  }, []);

  useEffect(() => {
    let eventSource;

    const fetchExercise = () => {
      setRawExercise('');
      setExercise(null);
      setExerciseId(null);
      setAudioChunks([]);
      setError(null);
      setIsLoading(true);

      const token = localStorage.getItem('token');
      eventSource = new EventSource(`${api}?token=${token}`);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        
        if (data.type === 'audio_chunk') {
          setAudioChunks(prev => [...prev, {
            audio: data.audio,
            speaker: data.speaker,
            text: data.text,
            timestamp: data.timestamp
          }]);
        }
        else if (data.type === 'content') {
          setRawExercise(prev => {
            const newRawExercise = prev + data.delta;
            try {
              const repairedJson = jsonrepair(newRawExercise);
              const partialExercise = JSON.parse(repairedJson);
              setExercise(partialExercise);
            } catch (e) {
              // JSON still too incomplete to parse
            }
            return newRawExercise;
          });
        }
        else if (data.type === 'metadata') {
          setExerciseId(data.exerciseId);
        }
        else if (data.status === 'complete') {
          setIsLoading(false);
          eventSource.close();
        }
      };

      eventSource.onerror = (error) => {
        setError('Failed to connect to exercise stream');
        setIsLoading(false);
        eventSource.close();
      };
    };

    fetchExercise();

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [api, refetchTrigger]);

  return { 
    exercise, 
    exerciseId, 
    audioChunks, 
    isLoading, 
    error, 
    refetchExercise 
  };
};

export default useStreamExercise;