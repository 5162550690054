import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const Graph = ({ graph }) => {
  const [aspectRatio, setAspectRatio] = useState(window.innerWidth > 768 ? 2 : 1.5);

  useEffect(() => {
    const handleResize = () => {
      setAspectRatio(window.innerWidth >= 768 ? 2 : 1.25);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!graph) return null;

  const data = {
    labels: graph.xAxisValues || [],
    datasets: (graph.series || []).map((s, index) => {
      return {
        label: s.name,
        data: s.data,
        fill: false,
        borderColor: index === 0 ? 'rgba(255, 99, 132, 1)' : 
                    index === 1 ? 'rgba(54, 162, 235, 1)' : 
                    'rgba(75, 192, 192, 1)',
        backgroundColor: index === 0 ? 'rgba(255, 99, 132, 0.2)' : 
                        index === 1 ? 'rgba(54, 162, 235, 0.2)' : 
                        'rgba(75, 192, 192, 0.2)',
        borderDash: index === 0 ? [5, 5] : 
                   index === 1 ? [15, 5] : 
                   [25, 5],
        borderWidth: 3,
      };
    }).filter(Boolean), // Remove null datasets
  };

  const options = {
    scales: {
      x: {
        title: {
          display: !!graph.xAxis,
          text: graph.xAxis || '',
          font: {
            // size: 19,
          },
        },
        ticks: {
          font: {
            // size: 16,
          },
        },
      },
      y: {
        title: {
          display: !!graph.yAxis,
          text: graph.yAxis || '',
          font: {
            // size: 19,
          },
        },
        ticks: {
          font: {
            // size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: graph.series?.some(s => s?.name),
        labels: {
          font: {
            // size: 16,
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    aspectRatio: aspectRatio,
  };

  return (
    <div className="alert alert-light">
      {graph.title && (
        <div className="text-center fs-5 mb-2" style={{ color: 'black' }}>
          {graph.title}
        </div>
      )}
      <Line data={data} options={options} />
    </div>
  );
};

export default Graph;
