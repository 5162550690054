import React from "react";
import { useTranslation } from "react-i18next";

const ExerciseNavigation = ({ onBack, onNext }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-4 mb-4 text-center">
      <div className="mt-4">
        <>
          <button className="btn btn-secondary btn me-3" onClick={onBack}>
            <i className="bi bi-reply me-2"></i> {t('back')}
          </button>
          <button className="btn btn-primary btn" onClick={onNext}>
            <i className="bi bi-repeat me-2"></i> {t('new_exercise')}
          </button>
        </>
      </div>
    </div>
  );
};

export default ExerciseNavigation;
