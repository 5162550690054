const highlightExamples = (text) => {
  const parts = text.split(/(<example>.*?<\/example>|<speech>.*?<\/speech>)/g); // Split at both <ins> and <del> tags
  return parts.map((part, index) => {
    if (part.startsWith("<example>") && part.endsWith("</example>")) {
      return (
        <>
          {" "}
          <ins key={index} style={{ textDecoration: "underline" }}>
            {part.replace(/<\/?example>/g, "")}
          </ins>
        </>
      );
    } else if (part.startsWith("<speech>") && part.endsWith("</speech>")) {
      return (
        <>
          {" "}
          <del key={index} style={{ textDecoration: "underline" }}>
            {part.replace(/<\/?speech>/g, "")}
          </del>
        </>
      );
    }
    return part; // Return the plain text if no tags
  });
};

export default highlightExamples;
