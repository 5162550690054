import React from "react";
import { useTranslation } from "react-i18next"; // Import the translation hook
// Import images from your assets folder
import ivetaImage from "../assets/testimonials_iveta.jpg";
import danImage from "../assets/testimonials_dan.jpg";
import ondrejImage from "../assets/testimonials_ondrej.jpg";

const Testimonials = () => {
  const { t } = useTranslation(); // Initialize translation function

  const testimonials = [
    {
      quote: t("testimonial_ondrej_quote"),
      name: t("testimonial_ondrej_name"),
      image: ondrejImage,
    },
    {
      quote: t("testimonial_iveta_quote"),
      name: t("testimonial_iveta_name"),
      image: ivetaImage,
    },
    {
      quote: t("testimonial_dan_quote"),
      name: t("testimonial_dan_name"),
      image: danImage,
    },
  ];

  return (
    <div className="container col-xxl-10 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        {/* Translate the title and subtitle */}
        <h2 className="display-4 fw-normal text-body-emphasis">
          {t("testimonials_title")}
        </h2>
        <p className="fs-5 text-body-secondary">
          {t("testimonials_subtitle")}
        </p>
      </div>
      <div className="row justify-content-center">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="testimonial p-4 border rounded text-center" style={{ backgroundColor: '#fcfcfc' }}>
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="rounded-circle mb-3"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
              />
              <p className="lead fs-5">"{testimonial.quote}"</p>
              <h5 className="lead mt-3 fw-medium">- {testimonial.name}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
