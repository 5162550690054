import React from 'react';

const Stars = ({ score }) => {
  const roundedScore = Math.round(score * 10) / 2; // Round to nearest 0.5
  const fullStars = Math.floor(roundedScore); // Full stars
  const halfStar = roundedScore % 1 !== 0; // Check if there is a half star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Calculate empty stars

  return (
    <>
      {[...Array(fullStars)].map((_, i) => (
        <i key={i} className="bi bi-star-fill" />
      ))}
      {halfStar && <i className="bi bi-star-half" />}
      {[...Array(emptyStars)].map((_, i) => (
        <i key={i} className="bi bi-star" />
      ))}
    </>
  );
};

export default Stars;
