import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import ReadingExercise from "./ReadingExercise";
import WritingExercise from "./WritingExercise";
import ListeningExercise from "./ListeningExercise";
import SpeakingExercise from "./SpeakingExercise";
import RealtimeSpeakingExercise from './RealtimeSpeakingExercise';
import LoadingSpinner from './LoadingSpinner';
import axios from 'axios';

const Exercise = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { section, exercise } = useParams();
  const level = user.proficiencyLevel;
  const targetLanguage = user.targetLanguage;
  const topic = user.topic;
  const [loading, setLoading] = useState(true);

  const api = `/api/exercises/general/${targetLanguage}/${level}/${section}/${exercise}/${topic}`;

  useEffect(() => {
    const checkLimit = async () => {
      try {
        // Retrieve token from local storage
        const token = localStorage.getItem('token');

        // Make an API call to check the exercise usage for this section
        const response = await axios.get(`/api/user/exercise-usage/${section}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token here
          },
        });
        
        const { remaining } = response.data;

        // If limit is reached, redirect to the premium page
        if (remaining <= 0) navigate('/premium');
      } catch (error) {
        console.error("Error checking exercise limits:", error);
      } finally {
        setLoading(false); // Stop loading once check is complete
      }
    };

    checkLimit();
  }, [section, navigate]);

  // Show a loading indicator while checking limits
  if (loading) return <LoadingSpinner />;

  // Render the exercise component based on the section
  switch (section) {
    case "reading":
      return <ReadingExercise api={api} />;
    case "writing":
      return <WritingExercise api={api} />;
    case "listening":
      return <ListeningExercise api={api} />;
    case "speaking":
      if (exercise === 'interview' || exercise === 'discussion') return <RealtimeSpeakingExercise api={api} />;
      else return <SpeakingExercise api={api} />;
    default:
      return null;
  }
};

export default Exercise;
