import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const SignUpNow = () => {
  const { t } = useTranslation(); // Initialize translation function
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/signup');
  };

  return (
    <div className="container col-xxl-8 px-4 pt-5 pb-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">
          {t('signup_now_title')}
        </h2>
        <p className="fs-5 text-body-secondary">
          {t('signup_now_subtitle')}
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <button 
          type="button" 
          className="btn btn-primary btn-lg fs-4 px-5" 
          onClick={handleButtonClick}
        >
          {t('signup_now_button')}
        </button>
      </div>
    </div>
  );
};

export default SignUpNow;
