import React from "react";

const LoadingSpinner = ({ inline = false }) => {
  if (inline) return (
    <div className="d-flex justify-content-center ms-2">
      <div className="spinner-border text-primary" role="status" />
    </div>
  );
  else return (
    <div className="d-flex justify-content-center mt-5">
      <div className="spinner-border text-primary" role="status" />
    </div>
  );
};

export default LoadingSpinner;
