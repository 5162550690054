import React, { useState } from "react";
import GapItem from "./GapItem";
import Explanations from "./Explanations";

const GapFilling = ({ passageTitle, passageSegments, onComplete }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);

  const handleOptionChange = (gapIndex, selectedOption) => {
    let gapCounter = 0;

    const gapSegment = passageSegments.find((segment, idx) => {
      if (segment.gap) {
        if (gapCounter === gapIndex) return true;
        gapCounter++;
      }
      return false;
    });
    
    if (!gapSegment?.gap) return;

    const isCorrect = selectedOption === gapSegment.gap.answer;

    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [gapIndex]: { userAnswer: selectedOption, isCorrect } };

      const newExplanation = {
        number: gapIndex + 1,
        text: gapSegment.gap.explanation
      };
      setExplanations((prevExplanations) => [...prevExplanations, newExplanation]);

      const totalGaps = passageSegments.filter(segment => segment.gap).length;
      if (Object.keys(newAnswers).length === totalGaps) {
        const score = Object.values(newAnswers).filter(answer => answer.isCorrect).length / totalGaps;
        onComplete(score);
      }

      return newAnswers;
    });
  };

  const renderFilledPassage = () => {
    if (!passageSegments?.length) return null;
    
    let gapCounter = 0;

    return (
      <div className="alert alert-light" style={{ textAlign: "justify" }}>
        {passageTitle && (
          <div className="fs-4 mb-3 text-center">{passageTitle}</div>
        )}
        {passageSegments.map((segment, index) => (
          <React.Fragment key={index}>
            {segment.text && <span>{segment.text}</span>}
            {segment.gap && (
              <GapItem
                gapData={segment.gap}
                gapIndex={gapCounter++}
                selectedOption={userAnswers[gapCounter - 1]?.userAnswer}
                isCorrect={userAnswers[gapCounter - 1]?.isCorrect}
                onOptionChange={handleOptionChange}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div>
      {renderFilledPassage()}
      {explanations.length > 0 && <Explanations explanations={explanations} />}
    </div>
  );
};

export default GapFilling;
