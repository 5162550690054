import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import founderPhoto from '../assets/founder_photo.jpg'; // Replace with your actual image path

const AboutUs = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('about_us_title')}</h2>
        <p className="fs-5 text-body-secondary">
          {t('about_us_subtitle')}
        </p>
      </div>
      <div className="row align-items-center justify-content-center">
        {/* Left side - Photo */}
        <div className="col-md-4 text-center mb-4 mb-md-0">
          <img
            src={founderPhoto}
            alt={t('about_us_founder_title')}
            className="img-fluid rounded-circle"
            style={{ width: '280px', height: '280px', objectFit: 'cover' }}
          />
          <h5 className="mt-3 text-body-emphasis">{t('about_us_founder_title')}</h5>
        </div>
        {/* Right side - Text */}
        <div className="col-md-8">
          <p className="lead">
            {t('about_us_paragraph_1')}
          </p>
          <p className="lead">
            {t('about_us_paragraph_2')}
          </p>
          <p className="lead">
            {t('about_us_paragraph_3')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
