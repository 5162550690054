import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const GapItem = ({ gapData, gapIndex, selectedOption, isCorrect, onOptionChange }) => {
  const handleSelectChange = (e) => {
    onOptionChange(gapIndex, e.target.value);
  };

  return (
    <span>
      <strong>({gapIndex + 1})</strong>
      {selectedOption ? (
        <span style={{ borderBottom: '1px solid black', paddingBottom: '2px' }}>&nbsp;
          {isCorrect ? (
            <span style={{ color: '#198754' }}>{selectedOption}&nbsp;</span>
          ) : (
            <span>
              <span style={{
                color: '#dc3545',
                textDecorationLine: 'line-through',
                textDecorationColor: '#dc3545',
              }}>
                <span>{selectedOption}</span>
              </span>
              <span style={{ color: '#198754' }}> {gapData?.answer}&nbsp;</span>
            </span>
          )}
        </span>
      ) : (
        <select
          onChange={handleSelectChange}
          value=""
          className="form-select form-select-sm mx-1"
          style={{
            display: 'inline-block',
            width: 'auto',
            padding: '0.175rem 0.75rem',
            fontSize: '0.875rem',
            lineHeight: '1.5',
            color: '#495057',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            cursor: 'pointer',
            minWidth: '100px',
            margin: '0.175rem 0'
          }}
        >
          <option value="" disabled>Select</option>
          {gapData?.options?.map((option, optIndex) => (
            <option key={optIndex} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </span>
  );
};

export default GapItem;
