import React from "react";
import { useTranslation } from 'react-i18next';
import Stars from "./Stars";
import highlightExamples from "../utils/highlightExamples";

const FeedbackSection = ({ title, data }) => {
  if (!data) return null;
  
  return (
    <div className="mb-4">
      <h3 className="fs-5">
        <div className="d-flex align-items-center flex-column flex-sm-row">
          <div className="d-flex align-items-center">
            {title}:
            <span className="ms-2">
              {data.score !== undefined && <Stars score={data.score} />}
            </span>
          </div>
          {data.feedback && (
            <div className="mt-2 mb-1 mt-sm-0 mb-sm-0 ms-sm-2 fs-6 fw-normal text-secondary">
              {highlightExamples(data.feedback)}
            </div>
          )}
        </div>
      </h3>
      {data.suggestions && data.suggestions.length > 0 && (
        <div className="mt-2">
          {data.suggestions.map((suggestion, index) => (
            <div key={index} className="border-start border-3 border-primary-subtle ps-3 mb-2">
              {highlightExamples(suggestion)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const FeedbackWriting = ({ feedback }) => {
  const { t } = useTranslation();

  if (!feedback) return null;

  return (
    <>
      {feedback.score !== undefined && (
        <div className="alert alert-light mt-3 pb-0" role="alert">
          <div className="mb-4 p-3 bg-warning-subtle rounded-3">
          <h2 className="text-center mb-2">
            <Stars score={feedback.score} />
          </h2>
          {feedback.feedback && (
            <div className="text-center fs-6 fw-normal">
              {highlightExamples(feedback.feedback)}
            </div>
          )}
        </div>
        <div>
          <FeedbackSection title={t('task')} data={feedback.task} />
          <FeedbackSection title={t('structure')} data={feedback.structure} />
          <FeedbackSection title={t('vocabulary')} data={feedback.vocabulary} />
          <FeedbackSection title={t('grammar')} data={feedback.grammar} />
        </div>
      </div>
      )}
    </>
  );
};

export default FeedbackWriting;
