import React from "react";
import Input from "./Input";

const BlankItem = ({ index, question, userAnswer, result, onInputChange, onSubmit, onHover, onLeave }) => {
  if (!question?.sentence) {
    return null;
  }

  const renderSentenceWithBlanks = () => {
    const parts = question.sentence.split("_");

    return (
      <div className="mb-3">
        <span>{index + 1}.&nbsp;</span>
        <div style={{ display: "inline", whiteSpace: "normal" }}>
          {parts.map((part, i) => (
            <React.Fragment key={i}>
              <span>{part}</span>
              {i < parts.length - 1 && (
                <Input
                  userAnswer={userAnswer}
                  onInputChange={(value) => onInputChange(index, value)}
                  onSubmit={() => onSubmit(index)}
                  isSubmitted={!!result}
                  isCorrect={result?.isCorrect}
                  correctAnswer={result?.correctAnswer}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="question-item mb-2" onMouseEnter={onHover} onMouseLeave={onLeave}>
      {renderSentenceWithBlanks()}
      {result && (
        <div className="alert alert-warning mt-2" role="alert">
          {result.explanation || "No explanation provided."}
        </div>
      )}
    </div>
  );
};

export default BlankItem;
