import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { updateProficiencyLevel, updateTargetLanguage, updateTopic } from '../services/userService';
import { useTranslation } from 'react-i18next';

const Toolbar = ({ disabled = false }) => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(AuthContext);
  const [targetLanguage, setTargetLanguage] = useState(user?.targetLanguage || 'EN');  // Default to English
  const [proficiencyLevel, setProficiencyLevel] = useState(user?.proficiencyLevel || 'A1');
  const [topic, setTopic] = useState(user?.topic || 'general');
  const [loading, setLoading] = useState(false);

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setTargetLanguage(newLanguage);
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const updatedUser = await updateTargetLanguage(newLanguage, token);
      setUser((prevUser) => ({ ...prevUser, targetLanguage: updatedUser.targetLanguage }));
    } catch (error) {
      console.error("Error updating language:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLevelChange = async (e) => {
    const newLevel = e.target.value;
    setProficiencyLevel(newLevel);
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const updatedUser = await updateProficiencyLevel(newLevel, token);
      setUser((prevUser) => ({ ...prevUser, proficiencyLevel: updatedUser.proficiencyLevel }));
    } catch (error) {
      console.error("Error updating proficiency level:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTopicChange = async (e) => {
    const newTopic = e.target.value;
    setTopic(newTopic);
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const updatedUser = await updateTopic(newTopic, token);
      setUser((prevUser) => ({ ...prevUser, topic: updatedUser.topic }));
    } catch (error) {
      console.error("Error updating topic:", error);
    } finally {
      setLoading(false);
    }
  };

  const languages = [
    { code: "AF", name: "Afrikaans" },
    { code: "AR", name: "العربية" }, // Arabic
    { code: "HY", name: "Հայերեն" }, // Armenian
    { code: "AZ", name: "Azərbaycanca" }, // Azerbaijani
    { code: "BE", name: "Беларуская" }, // Belarusian
    { code: "BS", name: "Bosanski" }, // Bosnian
    { code: "BG", name: "Български" }, // Bulgarian
    { code: "CA", name: "Català" }, // Catalan
    { code: "ZH", name: "中文" }, // Chinese
    { code: "HR", name: "Hrvatski" }, // Croatian
    { code: "CS", name: "Čeština" }, // Czech
    { code: "DA", name: "Dansk" }, // Danish
    { code: "NL", name: "Nederlands" }, // Dutch
    { code: "EN", name: "English" }, // English
    { code: "ET", name: "Eesti" }, // Estonian
    { code: "FI", name: "Suomi" }, // Finnish
    { code: "FR", name: "Français" }, // French
    { code: "GL", name: "Galego" }, // Galician
    { code: "DE", name: "Deutsch" }, // German
    { code: "EL", name: "Ελληνικά" }, // Greek
    { code: "HE", name: "עברית" }, // Hebrew
    { code: "HI", name: "हिन्दी" }, // Hindi
    { code: "HU", name: "Magyar" }, // Hungarian
    { code: "IS", name: "Íslenska" }, // Icelandic
    { code: "ID", name: "Bahasa Indonesia" }, // Indonesian
    { code: "IT", name: "Italiano" }, // Italian
    { code: "JA", name: "日本語" }, // Japanese
    { code: "KN", name: "ಕನ್ನಡ" }, // Kannada
    { code: "KK", name: "Қазақша" }, // Kazakh
    { code: "KO", name: "한국어" }, // Korean
    { code: "LV", name: "Latviešu" }, // Latvian
    { code: "LT", name: "Lietuvių" }, // Lithuanian
    { code: "MK", name: "Македонски" }, // Macedonian
    { code: "MS", name: "Bahasa Melayu" }, // Malay
    { code: "MR", name: "मराठी" }, // Marathi
    { code: "MI", name: "Māori" }, // Maori
    { code: "NE", name: "नेपाली" }, // Nepali
    { code: "NO", name: "Norsk" }, // Norwegian
    { code: "FA", name: "فارسی" }, // Persian
    { code: "PL", name: "Polski" }, // Polish
    { code: "PT", name: "Português" }, // Portuguese
    { code: "RO", name: "Română" }, // Romanian
    { code: "RU", name: "Русский" }, // Russian
    { code: "SR", name: "Српски" }, // Serbian
    { code: "SK", name: "Slovenčina" }, // Slovak
    { code: "SL", name: "Slovenščina" }, // Slovenian
    { code: "ES", name: "Español" }, // Spanish
    { code: "SW", name: "Kiswahili" }, // Swahili
    { code: "SV", name: "Svenska" }, // Swedish
    { code: "TL", name: "Tagalog" }, // Tagalog
    { code: "TA", name: "தமிழ்" }, // Tamil
    { code: "TH", name: "ไทย" }, // Thai
    { code: "TR", name: "Türkçe" }, // Turkish
    { code: "UK", name: "Українська" }, // Ukrainian
    { code: "UR", name: "اردو" }, // Urdu
    { code: "VI", name: "Tiếng Việt" }, // Vietnamese
    { code: "CY", name: "Cymraeg" }, // Welsh
  ];

  // Sort languages alphabetically by their native name
  languages.sort((a, b) => a.name.localeCompare(b.name, 'default', { sensitivity: 'base' }));  

  const topics = [
    { value: 'general', label: t('general') },
    { value: 'business', label: t('business') },
    { value: 'technology', label: t('technology') },
    { value: 'science', label: t('science') },
    { value: 'arts', label: t('arts') },
    { value: 'sports', label: t('sports') },
    { value: 'travel', label: t('travel') },
    { value: 'food', label: t('food') },
    { value: 'health', label: t('health') },
    { value: 'education', label: t('education') },
    { value: 'entertainment', label: t('entertainment') },
    { value: 'politics', label: t('politics') },
    { value: 'environment', label: t('environment') },
    { value: 'lifestyle', label: t('lifestyle') },
  ];

  return (
    <div className="alert alert-light pt-0 pb-2 mb-4" role="alert" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      
      {/* Language Dropdown with Bootstrap Globe Icon */}
      <div className="form-group d-flex align-items-center me-2 mt-2">
        <i className="bi bi-globe fs-3"></i>
        <select
          id="targetLanguage"
          value={targetLanguage}
          onChange={handleLanguageChange}
          className="form-select ms-2"
          style={{ width: '100%' }}
          disabled={loading || disabled}
        >
          {languages.map(lang => (
            <option key={lang.code} value={lang.code}>{lang.name}</option>
          ))}
        </select>
      </div>

      {/* Proficiency Level Dropdown with Bootstrap Mortarboard Icon */}
      <div className="form-group d-flex align-items-center ms-1 me-2 mt-2">
        <i className="bi bi-bar-chart fs-3"></i>
        <select
          id="proficiencyLevel"
          value={proficiencyLevel}
          onChange={handleLevelChange}
          className="form-select ms-2"
          style={{ width: '100%' }}
          disabled={loading || disabled}
        >
          <option value="A1">{t('level_a1')} (A1)</option>
          <option value="A2">{t('level_a2')} (A2)</option>
          <option value="B1">{t('level_b1')} (B1)</option>
          <option value="B2">{t('level_b2')} (B2)</option>
          <option value="C1">{t('level_c1')} (C1)</option>
          <option value="C2">{t('level_c2')} (C2)</option>
        </select>
      </div>

      {/* Topic Dropdown with Bootstrap Book Icon */}
      <div className="form-group d-flex align-items-center ms-1 me-2 mt-2">
        <i className="bi bi-book fs-3"></i>
        <select
          id="topic"
          value={topic}
          onChange={handleTopicChange}
          className="form-select ms-2"
          style={{ width: '100%' }}
          disabled={loading || disabled}
        >
          {topics.map(t => (
            <option key={t.value} value={t.value}>{t.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Toolbar;
