import React, { useState } from "react";
import Input from "./Input";
import Explanations from "./Explanations";
import styles from '../styles/TableCompletion.module.css';
import checkAnswer from '../utils/checkAnswer';
const areAllBlanksFilled = (userAnswers, answers) => {
  if (!answers?.length) return false;
  return answers.every((answer) => userAnswers[answer.placeholder]?.submitted);
};

const calculateScore = (userAnswers, answers) => {
  if (!answers?.length) return 0;
  return answers.filter(
    (answer) => userAnswers[answer.placeholder]?.isCorrect
  ).length / answers.length;
};

const TableCompletion = ({ table, answers, onComplete, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);

  const handleInputChange = (placeholder, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [placeholder]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  const handleSubmit = (placeholder) => {
    const userAnswer = userAnswers[placeholder]?.userAnswer;
    const answerObj = answers?.find(
      (answer) => answer.placeholder === placeholder
    );

    if (userAnswer && answerObj) {
      const isCorrect = checkAnswer(userAnswer, answerObj.correctAnswer);

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [placeholder]: { userAnswer, isCorrect, submitted: true },
        };

        setExplanations((prevExplanations) => [
          ...prevExplanations,
          { number: placeholder.match(/\d+/)[0], text: answerObj.explanation }
        ]);

        onHighlightChange(answerObj.reference);

        if (areAllBlanksFilled(updatedAnswers, answers)) {
          const score = calculateScore(updatedAnswers, answers);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  // Return null if table data isn't loaded yet
  if (!table?.columns || !table?.rows || !Array.isArray(table.columns) || !Array.isArray(table.rows)) {
    return null;
  }

  const renderTable = () => (
    <table className={`table ${styles.roundedTable}`}>
      <thead>
        <tr>
          {table.columns.map((col, index) => (
            <th key={index}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.rows.map((row, rowIndex) => {
          // Skip incomplete rows
          if (!Array.isArray(row)) return null;

          return (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                // Skip undefined cells
                if (cell === undefined) return null;

                return (
                  <td key={cellIndex}>
                    {/^_\d+_$/.test(cell) ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="fw-bold me-1">({cell.match(/\d+/)[0]})</div>
                        <Input
                          userAnswer={userAnswers[cell]?.userAnswer}
                          onInputChange={(value) => handleInputChange(cell, value)}
                          onSubmit={() => handleSubmit(cell)}
                          isSubmitted={userAnswers[cell]?.submitted}
                          isCorrect={userAnswers[cell]?.isCorrect}
                          correctAnswer={answers?.find((a) => a.placeholder === cell)?.correctAnswer}
                        />
                      </div>
                    ) : (
                      cell
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div>
      <div className={styles.tableContainer}>
        {renderTable()}
      </div>
      {explanations.length > 0 && <Explanations explanations={explanations} />}
    </div>
  );
};

export default TableCompletion;
